import React from 'react';

interface Props {
  color?: string;
}
const ArrowsButtonRightMin = ({ color = '#8D9297' }: Props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.2998 11.625L8.72168 8.28125L5.2998 4.95312L6.2373 4L10.6436 8.28125L6.2373 12.5781L5.2998 11.625Z"
        fill={color}
      />
    </svg>
  );
};

export default ArrowsButtonRightMin;
