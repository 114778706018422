import { fetch } from '@/utils/fetcher';

const getUserData = async () => {
  const res = await fetch().get('v1/me');

  return res.data;
};

const auth = {
  getUserData,
};
export default auth;
