import React from 'react';
import styled from 'styled-components';

import { RenderFallbackProps } from '@/components/atom/ErrorBoundary';
import WarningCircle from '@/components/atom/icon/WarningCircle';

const RootErrorFallback = (props: RenderFallbackProps) => {
  const { reset } = props;

  return (
    <Wrapper>
      <WarningCircle />
      <h1>에러가 발생했어요</h1>
      <p>
        일시적인 오류로 인해 페이지 접근을 할 수 없어요.
        <br />
        잠시 후 다시 접속해 보세요.
      </p>
      <button onClick={reset}>홈 화면으로 이동</button>
    </Wrapper>
  );
};
export default RootErrorFallback;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 96px 0 136px 0;

  & > svg {
    margin-bottom: 32px;
  }
  & > h1 {
    ${({ theme }) => theme.typography.UI22SB};
    color: ${({ theme }) => theme.color.gray700};
    margin-bottom: 10px;
  }

  & > p {
    display: flex;
    justify-content: center;
    text-align: center;
    ${({ theme }) => theme.typography.UI14M};
    color: ${({ theme }) => theme.color.gray500};
    margin-bottom: 24px;
  }

  & > button {
    border-radius: 8px;
    width: fit-content;
    padding: 10px 20px;
    ${({ theme }) => theme.typography.UI14M};
    color: ${({ theme }) => theme.color.gray600};
    border: none;
    background-color: ${({ theme }) => theme.color.gray50};
  }
`;
