import 'sanitize.css/sanitize.css';
import { createGlobalStyle } from 'styled-components';
import 'swiper/css';
import 'swiper/css/pagination';

const GlobalStyle = createGlobalStyle`

  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-bottom: calc(env(safe-area-inset-bottom) * 0.5);
  }

  * {
    font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
    scrollbar-width: none; /* Firefox */

    /* &:hover {
      transition: all 0.4s ease-out;
    } */
    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera*/
    }
  }
  /* 앱에서 실행될때 아래 동작을 막기 위해 추가 */
  * {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0); /* 버튼 클릭시 나오는 하이라이트 제거 */
    -webkit-touch-callout: none; /* 링크를 오래 눌렀을때 액션 막기*/
    -webkit-user-drag: none; /* 이미지, 글자 drag 막기*/
    -webkit-overflow-scrolling: touch;
  }

  html {
    font-size: 16px;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
  }
  
    button, li, a {
     &:hover {
       cursor: pointer;
     }
     &:focus {
      outline: none;
    }
  }

  a {
    text-decoration: none;
    outline: none;
    color: inherit;
  }

  input, textarea {
    resize: none;
    :focus {
      outline: none;
    }
  }

  &:not(input):not(textarea){
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  p, h1, h2, h3, h4,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video, pre {
    margin: 0;
    padding: 0;
    border: 0;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;
    font: inherit;
    color: inherit;
    cursor: pointer;
  }
  
  ol, ul {
    list-style: none;
  }

  nextjs-portal {
    display: none;
  }
`;

export default GlobalStyle;
