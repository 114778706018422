import hexToRgb from 'hex-rgb';

export const withOpacity = (
  color: string,
  opacity: number | string,
): string => {
  const { red, green, blue } = hexToRgb(color);
  const ret = `rgba(${red}, ${green}, ${blue}, ${opacity})`;
  return ret;
};
