import {
  MouseEvent,
  ReactElement,
  ReactNode,
  ReactPortal,
  useEffect,
} from 'react';
import { createPortal } from 'react-dom';

import { OverlayContainer } from '@/components/molecules/Overlay/styles';
import theme from '@/styles/theme';

export interface OverlayProps {
  index?: number;
  children: ReactNode;
  className?: string;
  backgroundColor?: string;
  onClickOverlay?: () => void;
}

const Overlay = (props: OverlayProps): ReactPortal | ReactElement => {
  const {
    index = 1,
    children,
    className,
    backgroundColor = theme.color.dimmed40,
    onClickOverlay,
  } = props;

  const handleClickOverlay = (event: MouseEvent<HTMLDivElement>) => {
    if (event.currentTarget === event.target && onClickOverlay) {
      onClickOverlay();
    } else {
      event.stopPropagation();
    }
  };

  useEffect(() => {
    document.body.style.cssText = `
      overflow: hidden;
    `;
    return () => {
      document.body.style.cssText = '';
    };
  }, []);

  if (typeof window !== 'object') {
    return <></>;
  }
  return createPortal(
    <OverlayContainer
      className={className}
      index={index}
      onClick={(event) => handleClickOverlay(event)}
      backgroundColor={backgroundColor}
    >
      {children}
    </OverlayContainer>,
    document.querySelector('#modal') as HTMLDivElement,
  );
};

export default Overlay;
