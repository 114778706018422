import { Component, ErrorInfo, PropsWithChildren, ReactNode } from 'react';

import { errorReport } from '@/utils/report';

export type RenderFallbackProps<ErrorType extends Error = Error> = {
  error: ErrorType;
  reset: () => void;
};

type RenderFallbackType = <ErrorType extends Error>(
  props: RenderFallbackProps<ErrorType>,
) => ReactNode;

type Props = {
  onReset?: () => void;
  renderFallback: RenderFallbackType;
};

type State = {
  error?: Error;
};

const initialState: State = {
  error: undefined,
};

class ErrorBoundary extends Component<PropsWithChildren<Props>, State> {
  constructor(props: Props) {
    super(props);
    this.state = { ...initialState };
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    errorReport(
      `at GlobalErrorBoundary ${error.message}`,
      { errorInfo },
      error,
    );
  }

  resetErrorBoundary = () => {
    this.setState({ ...initialState });
    if (this.props.onReset) this.props.onReset();
  };

  render() {
    const { children, renderFallback } = this.props;
    const { error } = this.state;

    if (error) {
      return renderFallback({
        error,
        reset: this.resetErrorBoundary,
      });
    }
    return children;
  }
}

export default ErrorBoundary;
