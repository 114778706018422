import { get } from 'lodash';

import { BFF_ERROR_CODE_TYPES, ERROR_MESSAGE } from '@/constants/errors';
import { GraphqlError } from '@/types/error';

export interface GraphqlErrors {
  extensions: { code: string };
  message: string;
  path: Array<string>;
}

export const getErrorCode = (errorCode?: BFF_ERROR_CODE_TYPES) => {
  if (!errorCode) return BFF_ERROR_CODE_TYPES.Default;
  if (!BFF_ERROR_CODE_TYPES[errorCode]) return BFF_ERROR_CODE_TYPES.Default;

  return BFF_ERROR_CODE_TYPES[errorCode];
};

export function getErrorCodeFromResponse(response: FailureBffResponseVO) {
  try {
    const { errors } = response;
    const [error] = errors;
    const { extensions } = error;

    return getErrorCode(extensions?.code);
  } catch (e) {
    return getErrorCode();
  }
}

export function getErrorMessageFromResponse(response: FailureBffResponseVO) {
  try {
    return response?.errors?.[0]?.message;
  } catch (e) {
    return '';
  }
}

export const checkErrorFromResponse = (response: FailureBFFResponse | any) => {
  return 'code' in response && !response.success;
};

export const getBffErrorMessage = (error?: unknown) => {
  const errorData = error as Error;
  if (errorData.message && errorData.message in ERROR_MESSAGE) {
    return get(ERROR_MESSAGE, errorData.message);
  }

  if ((errorData as GraphqlError).errorMessage) {
    return (errorData as GraphqlError).errorMessage;
  }

  return get(ERROR_MESSAGE, 'DefaultError');
};

export const getErrorObject = (error: unknown) => {
  let errorObject = undefined;
  if (error instanceof Error) {
    errorObject = {
      ...error,
    };
  }
  return errorObject;
};
