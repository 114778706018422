import * as Types from '../../../types/bff/gql-schema';

import { useQuery, useMutation, UseQueryOptions, UseMutationOptions } from '@tanstack/react-query';
import { gqlFetcher } from '@/utils/fetcher';

export const GetBoardCategoriesDocument = `
    query getBoardCategories {
  boardCategories {
    boardCategoryId
    imageUrl
    name
  }
}
    `;
export const useGetBoardCategoriesQuery = <
      TData = Types.GetBoardCategoriesQuery,
      TError = Types.GraphqlError
    >(
      variables?: Types.GetBoardCategoriesQueryVariables,
      options?: UseQueryOptions<Types.GetBoardCategoriesQuery, TError, TData>
    ) =>
    useQuery<Types.GetBoardCategoriesQuery, TError, TData>(
      variables === undefined ? ['getBoardCategories'] : ['getBoardCategories', variables],
      gqlFetcher<Types.GetBoardCategoriesQuery, Types.GetBoardCategoriesQueryVariables>(GetBoardCategoriesDocument, variables),
      options
    );

useGetBoardCategoriesQuery.getKey = (variables?: Types.GetBoardCategoriesQueryVariables) => variables === undefined ? ['getBoardCategories'] : ['getBoardCategories', variables];
;

useGetBoardCategoriesQuery.fetcher = (variables?: Types.GetBoardCategoriesQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetBoardCategoriesQuery, Types.GetBoardCategoriesQueryVariables>(GetBoardCategoriesDocument, variables, options);
export const GetRecommendedPopularBoardPostsDocument = `
    query getRecommendedPopularBoardPosts($size: Int!) {
  recommendedPopularBoardPosts(size: $size) {
    boardPostPid
    isPopular
    isRecommended
    title
  }
}
    `;
export const useGetRecommendedPopularBoardPostsQuery = <
      TData = Types.GetRecommendedPopularBoardPostsQuery,
      TError = Types.GraphqlError
    >(
      variables: Types.GetRecommendedPopularBoardPostsQueryVariables,
      options?: UseQueryOptions<Types.GetRecommendedPopularBoardPostsQuery, TError, TData>
    ) =>
    useQuery<Types.GetRecommendedPopularBoardPostsQuery, TError, TData>(
      ['getRecommendedPopularBoardPosts', variables],
      gqlFetcher<Types.GetRecommendedPopularBoardPostsQuery, Types.GetRecommendedPopularBoardPostsQueryVariables>(GetRecommendedPopularBoardPostsDocument, variables),
      options
    );

useGetRecommendedPopularBoardPostsQuery.getKey = (variables: Types.GetRecommendedPopularBoardPostsQueryVariables) => ['getRecommendedPopularBoardPosts', variables];
;

useGetRecommendedPopularBoardPostsQuery.fetcher = (variables: Types.GetRecommendedPopularBoardPostsQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetRecommendedPopularBoardPostsQuery, Types.GetRecommendedPopularBoardPostsQueryVariables>(GetRecommendedPopularBoardPostsDocument, variables, options);
export const GetNewestBoardPostCursorPageDocument = `
    query getNewestBoardPostCursorPage($request: NewestBoardPostCursorPageRequestInput!) {
  newestBoardPostCursorPage(request: $request) {
    elements {
      boardPostPid
      title
      content
      createdAt
      isPopular
      isRecommended
      likeCount
      boardCommentCount
      boardPostAttachments {
        boardPostAttachmentId
        boardPostPid
        blindType
      }
      communityUser {
        nickname
        userId
        profileImageUrl
      }
      boardCategory {
        name
      }
      isLikedByMe
      isMine
      blinded
    }
    pivot
  }
}
    `;
export const useGetNewestBoardPostCursorPageQuery = <
      TData = Types.GetNewestBoardPostCursorPageQuery,
      TError = Types.GraphqlError
    >(
      variables: Types.GetNewestBoardPostCursorPageQueryVariables,
      options?: UseQueryOptions<Types.GetNewestBoardPostCursorPageQuery, TError, TData>
    ) =>
    useQuery<Types.GetNewestBoardPostCursorPageQuery, TError, TData>(
      ['getNewestBoardPostCursorPage', variables],
      gqlFetcher<Types.GetNewestBoardPostCursorPageQuery, Types.GetNewestBoardPostCursorPageQueryVariables>(GetNewestBoardPostCursorPageDocument, variables),
      options
    );

useGetNewestBoardPostCursorPageQuery.getKey = (variables: Types.GetNewestBoardPostCursorPageQueryVariables) => ['getNewestBoardPostCursorPage', variables];
;

useGetNewestBoardPostCursorPageQuery.fetcher = (variables: Types.GetNewestBoardPostCursorPageQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetNewestBoardPostCursorPageQuery, Types.GetNewestBoardPostCursorPageQueryVariables>(GetNewestBoardPostCursorPageDocument, variables, options);
export const GetSearchBoardPostCursorPageDocument = `
    query getSearchBoardPostCursorPage($request: SearchBoardPostCursorPageRequestInput!) {
  searchBoardPostCursorPage(request: $request) {
    elements {
      boardPostPid
      title
      content
      createdAt
      isPopular
      isRecommended
      likeCount
      boardCommentCount
      boardPostAttachments {
        boardPostAttachmentId
        boardPostPid
        blindType
      }
      communityUser {
        nickname
        userId
        profileImageUrl
      }
      boardCategory {
        name
      }
      isLikedByMe
      isMine
      blinded
    }
    pivot
  }
}
    `;
export const useGetSearchBoardPostCursorPageQuery = <
      TData = Types.GetSearchBoardPostCursorPageQuery,
      TError = Types.GraphqlError
    >(
      variables: Types.GetSearchBoardPostCursorPageQueryVariables,
      options?: UseQueryOptions<Types.GetSearchBoardPostCursorPageQuery, TError, TData>
    ) =>
    useQuery<Types.GetSearchBoardPostCursorPageQuery, TError, TData>(
      ['getSearchBoardPostCursorPage', variables],
      gqlFetcher<Types.GetSearchBoardPostCursorPageQuery, Types.GetSearchBoardPostCursorPageQueryVariables>(GetSearchBoardPostCursorPageDocument, variables),
      options
    );

useGetSearchBoardPostCursorPageQuery.getKey = (variables: Types.GetSearchBoardPostCursorPageQueryVariables) => ['getSearchBoardPostCursorPage', variables];
;

useGetSearchBoardPostCursorPageQuery.fetcher = (variables: Types.GetSearchBoardPostCursorPageQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetSearchBoardPostCursorPageQuery, Types.GetSearchBoardPostCursorPageQueryVariables>(GetSearchBoardPostCursorPageDocument, variables, options);
export const GetBoardPopularSearchKeywordsDocument = `
    query getBoardPopularSearchKeywords {
  popularSearchKeywords
}
    `;
export const useGetBoardPopularSearchKeywordsQuery = <
      TData = Types.GetBoardPopularSearchKeywordsQuery,
      TError = Types.GraphqlError
    >(
      variables?: Types.GetBoardPopularSearchKeywordsQueryVariables,
      options?: UseQueryOptions<Types.GetBoardPopularSearchKeywordsQuery, TError, TData>
    ) =>
    useQuery<Types.GetBoardPopularSearchKeywordsQuery, TError, TData>(
      variables === undefined ? ['getBoardPopularSearchKeywords'] : ['getBoardPopularSearchKeywords', variables],
      gqlFetcher<Types.GetBoardPopularSearchKeywordsQuery, Types.GetBoardPopularSearchKeywordsQueryVariables>(GetBoardPopularSearchKeywordsDocument, variables),
      options
    );

useGetBoardPopularSearchKeywordsQuery.getKey = (variables?: Types.GetBoardPopularSearchKeywordsQueryVariables) => variables === undefined ? ['getBoardPopularSearchKeywords'] : ['getBoardPopularSearchKeywords', variables];
;

useGetBoardPopularSearchKeywordsQuery.fetcher = (variables?: Types.GetBoardPopularSearchKeywordsQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetBoardPopularSearchKeywordsQuery, Types.GetBoardPopularSearchKeywordsQueryVariables>(GetBoardPopularSearchKeywordsDocument, variables, options);
export const GetBoardPostDocument = `
    query getBoardPost($boardPostPid: ID!) {
  boardPost(boardPostPid: $boardPostPid) {
    boardPostPid
    boardCategoryId
    title
    content
    boardPostAttachments {
      boardPostAttachmentId
      blindType
    }
    communityUser {
      nickname
      userId
      profileImageUrl
    }
    boardCategory {
      name
    }
    likeCount
    boardCommentCount
    createdAt
    boardComments {
      boardCommentPid
      parentBoardCommentPid
      boardPostPid
      content
      createdAt
      communityUser {
        nickname
        userId
        profileImageUrl
      }
      deleted
      isLikedByMe
      isMine
      likeCount
      blinded
    }
    isLikedByMe
    isMine
    blinded
  }
}
    `;
export const useGetBoardPostQuery = <
      TData = Types.GetBoardPostQuery,
      TError = Types.GraphqlError
    >(
      variables: Types.GetBoardPostQueryVariables,
      options?: UseQueryOptions<Types.GetBoardPostQuery, TError, TData>
    ) =>
    useQuery<Types.GetBoardPostQuery, TError, TData>(
      ['getBoardPost', variables],
      gqlFetcher<Types.GetBoardPostQuery, Types.GetBoardPostQueryVariables>(GetBoardPostDocument, variables),
      options
    );

useGetBoardPostQuery.getKey = (variables: Types.GetBoardPostQueryVariables) => ['getBoardPost', variables];
;

useGetBoardPostQuery.fetcher = (variables: Types.GetBoardPostQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetBoardPostQuery, Types.GetBoardPostQueryVariables>(GetBoardPostDocument, variables, options);
export const SetLikeBoardPostDocument = `
    mutation setLikeBoardPost($boardPostPid: ID!) {
  likeBoardPost(boardPostPid: $boardPostPid)
}
    `;
export const useSetLikeBoardPostMutation = <
      TError = Types.GraphqlError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.SetLikeBoardPostMutation, TError, Types.SetLikeBoardPostMutationVariables, TContext>) =>
    useMutation<Types.SetLikeBoardPostMutation, TError, Types.SetLikeBoardPostMutationVariables, TContext>(
      ['setLikeBoardPost'],
      (variables?: Types.SetLikeBoardPostMutationVariables) => gqlFetcher<Types.SetLikeBoardPostMutation, Types.SetLikeBoardPostMutationVariables>(SetLikeBoardPostDocument, variables)(),
      options
    );
useSetLikeBoardPostMutation.fetcher = (variables: Types.SetLikeBoardPostMutationVariables, options?: RequestInit['headers']) => gqlFetcher<Types.SetLikeBoardPostMutation, Types.SetLikeBoardPostMutationVariables>(SetLikeBoardPostDocument, variables, options);
export const GetMyBoardPostCursorPageDocument = `
    query getMyBoardPostCursorPage($request: MyBoardPostCursorPageRequestInput!) {
  myBoardPostCursorPage(request: $request) {
    elements {
      boardPostPid
      title
      content
      createdAt
      isPopular
      isRecommended
      likeCount
      boardCommentCount
      boardPostAttachments {
        boardPostAttachmentId
        boardPostPid
        blindType
      }
      communityUser {
        nickname
        userId
        profileImageUrl
      }
      boardCategory {
        name
      }
      isLikedByMe
      isMine
      blinded
    }
    pivot
  }
}
    `;
export const useGetMyBoardPostCursorPageQuery = <
      TData = Types.GetMyBoardPostCursorPageQuery,
      TError = Types.GraphqlError
    >(
      variables: Types.GetMyBoardPostCursorPageQueryVariables,
      options?: UseQueryOptions<Types.GetMyBoardPostCursorPageQuery, TError, TData>
    ) =>
    useQuery<Types.GetMyBoardPostCursorPageQuery, TError, TData>(
      ['getMyBoardPostCursorPage', variables],
      gqlFetcher<Types.GetMyBoardPostCursorPageQuery, Types.GetMyBoardPostCursorPageQueryVariables>(GetMyBoardPostCursorPageDocument, variables),
      options
    );

useGetMyBoardPostCursorPageQuery.getKey = (variables: Types.GetMyBoardPostCursorPageQueryVariables) => ['getMyBoardPostCursorPage', variables];
;

useGetMyBoardPostCursorPageQuery.fetcher = (variables: Types.GetMyBoardPostCursorPageQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetMyBoardPostCursorPageQuery, Types.GetMyBoardPostCursorPageQueryVariables>(GetMyBoardPostCursorPageDocument, variables, options);
export const GetMyBoardCommentDocument = `
    query getMyBoardComment($request: MyBoardCommentCursorPageRequestInput!) {
  myBoardCommentCursorPage(request: $request) {
    elements {
      boardCommentPid
      parentBoardCommentPid
      boardPostPid
      content
      createdAt
      boardPost {
        boardPostPid
        title
      }
      isLikedByMe
      isMine
      likeCount
      blinded
    }
    pivot
  }
}
    `;
export const useGetMyBoardCommentQuery = <
      TData = Types.GetMyBoardCommentQuery,
      TError = Types.GraphqlError
    >(
      variables: Types.GetMyBoardCommentQueryVariables,
      options?: UseQueryOptions<Types.GetMyBoardCommentQuery, TError, TData>
    ) =>
    useQuery<Types.GetMyBoardCommentQuery, TError, TData>(
      ['getMyBoardComment', variables],
      gqlFetcher<Types.GetMyBoardCommentQuery, Types.GetMyBoardCommentQueryVariables>(GetMyBoardCommentDocument, variables),
      options
    );

useGetMyBoardCommentQuery.getKey = (variables: Types.GetMyBoardCommentQueryVariables) => ['getMyBoardComment', variables];
;

useGetMyBoardCommentQuery.fetcher = (variables: Types.GetMyBoardCommentQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetMyBoardCommentQuery, Types.GetMyBoardCommentQueryVariables>(GetMyBoardCommentDocument, variables, options);
export const SetLikeBoardCommentDocument = `
    mutation setLikeBoardComment($boardCommentPid: ID!) {
  likeBoardComment(boardCommentPid: $boardCommentPid)
}
    `;
export const useSetLikeBoardCommentMutation = <
      TError = Types.GraphqlError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.SetLikeBoardCommentMutation, TError, Types.SetLikeBoardCommentMutationVariables, TContext>) =>
    useMutation<Types.SetLikeBoardCommentMutation, TError, Types.SetLikeBoardCommentMutationVariables, TContext>(
      ['setLikeBoardComment'],
      (variables?: Types.SetLikeBoardCommentMutationVariables) => gqlFetcher<Types.SetLikeBoardCommentMutation, Types.SetLikeBoardCommentMutationVariables>(SetLikeBoardCommentDocument, variables)(),
      options
    );
useSetLikeBoardCommentMutation.fetcher = (variables: Types.SetLikeBoardCommentMutationVariables, options?: RequestInit['headers']) => gqlFetcher<Types.SetLikeBoardCommentMutation, Types.SetLikeBoardCommentMutationVariables>(SetLikeBoardCommentDocument, variables, options);
export const GetBoardCommentBelongedBoardCommentsDocument = `
    query getBoardCommentBelongedBoardComments($request: BoardCommentBelongedBoardCommentsRequestInput!) {
  boardCommentBelongedBoardComments(request: $request) {
    boardCommentPid
    parentBoardCommentPid
    boardPostPid
    content
    createdAt
    communityUser {
      nickname
      userId
      profileImageUrl
    }
    deleted
    isLikedByMe
    isMine
    likeCount
    deleted
    blinded
  }
}
    `;
export const useGetBoardCommentBelongedBoardCommentsQuery = <
      TData = Types.GetBoardCommentBelongedBoardCommentsQuery,
      TError = Types.GraphqlError
    >(
      variables: Types.GetBoardCommentBelongedBoardCommentsQueryVariables,
      options?: UseQueryOptions<Types.GetBoardCommentBelongedBoardCommentsQuery, TError, TData>
    ) =>
    useQuery<Types.GetBoardCommentBelongedBoardCommentsQuery, TError, TData>(
      ['getBoardCommentBelongedBoardComments', variables],
      gqlFetcher<Types.GetBoardCommentBelongedBoardCommentsQuery, Types.GetBoardCommentBelongedBoardCommentsQueryVariables>(GetBoardCommentBelongedBoardCommentsDocument, variables),
      options
    );

useGetBoardCommentBelongedBoardCommentsQuery.getKey = (variables: Types.GetBoardCommentBelongedBoardCommentsQueryVariables) => ['getBoardCommentBelongedBoardComments', variables];
;

useGetBoardCommentBelongedBoardCommentsQuery.fetcher = (variables: Types.GetBoardCommentBelongedBoardCommentsQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetBoardCommentBelongedBoardCommentsQuery, Types.GetBoardCommentBelongedBoardCommentsQueryVariables>(GetBoardCommentBelongedBoardCommentsDocument, variables, options);
export const GetUserProfileImageUrlDocument = `
    query getUserProfileImageUrl {
  communityUser {
    userId
    profileImageUrl
  }
}
    `;
export const useGetUserProfileImageUrlQuery = <
      TData = Types.GetUserProfileImageUrlQuery,
      TError = Types.GraphqlError
    >(
      variables?: Types.GetUserProfileImageUrlQueryVariables,
      options?: UseQueryOptions<Types.GetUserProfileImageUrlQuery, TError, TData>
    ) =>
    useQuery<Types.GetUserProfileImageUrlQuery, TError, TData>(
      variables === undefined ? ['getUserProfileImageUrl'] : ['getUserProfileImageUrl', variables],
      gqlFetcher<Types.GetUserProfileImageUrlQuery, Types.GetUserProfileImageUrlQueryVariables>(GetUserProfileImageUrlDocument, variables),
      options
    );

useGetUserProfileImageUrlQuery.getKey = (variables?: Types.GetUserProfileImageUrlQueryVariables) => variables === undefined ? ['getUserProfileImageUrl'] : ['getUserProfileImageUrl', variables];
;

useGetUserProfileImageUrlQuery.fetcher = (variables?: Types.GetUserProfileImageUrlQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetUserProfileImageUrlQuery, Types.GetUserProfileImageUrlQueryVariables>(GetUserProfileImageUrlDocument, variables, options);
export const AddPostBoardCommentOfBoardPostDocument = `
    mutation addPostBoardCommentOfBoardPost($request: PostBoardCommentOfBoardPostRequestInput!) {
  postBoardCommentOfBoardPost(request: $request)
}
    `;
export const useAddPostBoardCommentOfBoardPostMutation = <
      TError = Types.GraphqlError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.AddPostBoardCommentOfBoardPostMutation, TError, Types.AddPostBoardCommentOfBoardPostMutationVariables, TContext>) =>
    useMutation<Types.AddPostBoardCommentOfBoardPostMutation, TError, Types.AddPostBoardCommentOfBoardPostMutationVariables, TContext>(
      ['addPostBoardCommentOfBoardPost'],
      (variables?: Types.AddPostBoardCommentOfBoardPostMutationVariables) => gqlFetcher<Types.AddPostBoardCommentOfBoardPostMutation, Types.AddPostBoardCommentOfBoardPostMutationVariables>(AddPostBoardCommentOfBoardPostDocument, variables)(),
      options
    );
useAddPostBoardCommentOfBoardPostMutation.fetcher = (variables: Types.AddPostBoardCommentOfBoardPostMutationVariables, options?: RequestInit['headers']) => gqlFetcher<Types.AddPostBoardCommentOfBoardPostMutation, Types.AddPostBoardCommentOfBoardPostMutationVariables>(AddPostBoardCommentOfBoardPostDocument, variables, options);
export const AddPostBoardCommentOfBoardCommentDocument = `
    mutation addPostBoardCommentOfBoardComment($request: PostBoardCommentOfBoardCommentRequestInput!) {
  postBoardCommentOfBoardComment(request: $request)
}
    `;
export const useAddPostBoardCommentOfBoardCommentMutation = <
      TError = Types.GraphqlError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.AddPostBoardCommentOfBoardCommentMutation, TError, Types.AddPostBoardCommentOfBoardCommentMutationVariables, TContext>) =>
    useMutation<Types.AddPostBoardCommentOfBoardCommentMutation, TError, Types.AddPostBoardCommentOfBoardCommentMutationVariables, TContext>(
      ['addPostBoardCommentOfBoardComment'],
      (variables?: Types.AddPostBoardCommentOfBoardCommentMutationVariables) => gqlFetcher<Types.AddPostBoardCommentOfBoardCommentMutation, Types.AddPostBoardCommentOfBoardCommentMutationVariables>(AddPostBoardCommentOfBoardCommentDocument, variables)(),
      options
    );
useAddPostBoardCommentOfBoardCommentMutation.fetcher = (variables: Types.AddPostBoardCommentOfBoardCommentMutationVariables, options?: RequestInit['headers']) => gqlFetcher<Types.AddPostBoardCommentOfBoardCommentMutation, Types.AddPostBoardCommentOfBoardCommentMutationVariables>(AddPostBoardCommentOfBoardCommentDocument, variables, options);
export const GetCommunityUserDocument = `
    query GetCommunityUser {
  communityUser {
    nickname
    userId
    postCommentNotificationAllowed
    postLikeNotificationAllowed
    qnaAnswerNotificationAllowed
    tutorialCompleted
    profileImageUrl
    isAdmin
    bannedUntil
  }
}
    `;
export const useGetCommunityUserQuery = <
      TData = Types.GetCommunityUserQuery,
      TError = Types.GraphqlError
    >(
      variables?: Types.GetCommunityUserQueryVariables,
      options?: UseQueryOptions<Types.GetCommunityUserQuery, TError, TData>
    ) =>
    useQuery<Types.GetCommunityUserQuery, TError, TData>(
      variables === undefined ? ['GetCommunityUser'] : ['GetCommunityUser', variables],
      gqlFetcher<Types.GetCommunityUserQuery, Types.GetCommunityUserQueryVariables>(GetCommunityUserDocument, variables),
      options
    );

useGetCommunityUserQuery.getKey = (variables?: Types.GetCommunityUserQueryVariables) => variables === undefined ? ['GetCommunityUser'] : ['GetCommunityUser', variables];
;

useGetCommunityUserQuery.fetcher = (variables?: Types.GetCommunityUserQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetCommunityUserQuery, Types.GetCommunityUserQueryVariables>(GetCommunityUserDocument, variables, options);
export const UpdateCommunityUserNicknameDocument = `
    mutation UpdateCommunityUserNickname($nickname: String!) {
  updateCommunityUserNickname(nickname: $nickname)
}
    `;
export const useUpdateCommunityUserNicknameMutation = <
      TError = Types.GraphqlError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateCommunityUserNicknameMutation, TError, Types.UpdateCommunityUserNicknameMutationVariables, TContext>) =>
    useMutation<Types.UpdateCommunityUserNicknameMutation, TError, Types.UpdateCommunityUserNicknameMutationVariables, TContext>(
      ['UpdateCommunityUserNickname'],
      (variables?: Types.UpdateCommunityUserNicknameMutationVariables) => gqlFetcher<Types.UpdateCommunityUserNicknameMutation, Types.UpdateCommunityUserNicknameMutationVariables>(UpdateCommunityUserNicknameDocument, variables)(),
      options
    );
useUpdateCommunityUserNicknameMutation.fetcher = (variables: Types.UpdateCommunityUserNicknameMutationVariables, options?: RequestInit['headers']) => gqlFetcher<Types.UpdateCommunityUserNicknameMutation, Types.UpdateCommunityUserNicknameMutationVariables>(UpdateCommunityUserNicknameDocument, variables, options);
export const GetValidateCommunityUserNicknameDocument = `
    query GetValidateCommunityUserNickname($nickname: String!) {
  validateCommunityUserNickname(nickname: $nickname) {
    resultType
    available
  }
}
    `;
export const useGetValidateCommunityUserNicknameQuery = <
      TData = Types.GetValidateCommunityUserNicknameQuery,
      TError = Types.GraphqlError
    >(
      variables: Types.GetValidateCommunityUserNicknameQueryVariables,
      options?: UseQueryOptions<Types.GetValidateCommunityUserNicknameQuery, TError, TData>
    ) =>
    useQuery<Types.GetValidateCommunityUserNicknameQuery, TError, TData>(
      ['GetValidateCommunityUserNickname', variables],
      gqlFetcher<Types.GetValidateCommunityUserNicknameQuery, Types.GetValidateCommunityUserNicknameQueryVariables>(GetValidateCommunityUserNicknameDocument, variables),
      options
    );

useGetValidateCommunityUserNicknameQuery.getKey = (variables: Types.GetValidateCommunityUserNicknameQueryVariables) => ['GetValidateCommunityUserNickname', variables];
;

useGetValidateCommunityUserNicknameQuery.fetcher = (variables: Types.GetValidateCommunityUserNicknameQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetValidateCommunityUserNicknameQuery, Types.GetValidateCommunityUserNicknameQueryVariables>(GetValidateCommunityUserNicknameDocument, variables, options);
export const GetCommunityUserProfileImageUploadInfoDocument = `
    query GetCommunityUserProfileImageUploadInfo($request: String!) {
  communityUserProfileImageUploadInfo(fileExtension: $request) {
    path
    preSignedUrl
  }
}
    `;
export const useGetCommunityUserProfileImageUploadInfoQuery = <
      TData = Types.GetCommunityUserProfileImageUploadInfoQuery,
      TError = Types.GraphqlError
    >(
      variables: Types.GetCommunityUserProfileImageUploadInfoQueryVariables,
      options?: UseQueryOptions<Types.GetCommunityUserProfileImageUploadInfoQuery, TError, TData>
    ) =>
    useQuery<Types.GetCommunityUserProfileImageUploadInfoQuery, TError, TData>(
      ['GetCommunityUserProfileImageUploadInfo', variables],
      gqlFetcher<Types.GetCommunityUserProfileImageUploadInfoQuery, Types.GetCommunityUserProfileImageUploadInfoQueryVariables>(GetCommunityUserProfileImageUploadInfoDocument, variables),
      options
    );

useGetCommunityUserProfileImageUploadInfoQuery.getKey = (variables: Types.GetCommunityUserProfileImageUploadInfoQueryVariables) => ['GetCommunityUserProfileImageUploadInfo', variables];
;

useGetCommunityUserProfileImageUploadInfoQuery.fetcher = (variables: Types.GetCommunityUserProfileImageUploadInfoQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetCommunityUserProfileImageUploadInfoQuery, Types.GetCommunityUserProfileImageUploadInfoQueryVariables>(GetCommunityUserProfileImageUploadInfoDocument, variables, options);
export const UpdateCommunityUserProfileImagePathDocument = `
    mutation UpdateCommunityUserProfileImagePath($profileImagePath: String!) {
  updateCommunityUserProfileImagePath(profileImagePath: $profileImagePath)
}
    `;
export const useUpdateCommunityUserProfileImagePathMutation = <
      TError = Types.GraphqlError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateCommunityUserProfileImagePathMutation, TError, Types.UpdateCommunityUserProfileImagePathMutationVariables, TContext>) =>
    useMutation<Types.UpdateCommunityUserProfileImagePathMutation, TError, Types.UpdateCommunityUserProfileImagePathMutationVariables, TContext>(
      ['UpdateCommunityUserProfileImagePath'],
      (variables?: Types.UpdateCommunityUserProfileImagePathMutationVariables) => gqlFetcher<Types.UpdateCommunityUserProfileImagePathMutation, Types.UpdateCommunityUserProfileImagePathMutationVariables>(UpdateCommunityUserProfileImagePathDocument, variables)(),
      options
    );
useUpdateCommunityUserProfileImagePathMutation.fetcher = (variables: Types.UpdateCommunityUserProfileImagePathMutationVariables, options?: RequestInit['headers']) => gqlFetcher<Types.UpdateCommunityUserProfileImagePathMutation, Types.UpdateCommunityUserProfileImagePathMutationVariables>(UpdateCommunityUserProfileImagePathDocument, variables, options);
export const GetCommunityUserProfileImagePresetsDocument = `
    query GetCommunityUserProfileImagePresets {
  communityUserProfileImagePresets {
    path
    url
  }
}
    `;
export const useGetCommunityUserProfileImagePresetsQuery = <
      TData = Types.GetCommunityUserProfileImagePresetsQuery,
      TError = Types.GraphqlError
    >(
      variables?: Types.GetCommunityUserProfileImagePresetsQueryVariables,
      options?: UseQueryOptions<Types.GetCommunityUserProfileImagePresetsQuery, TError, TData>
    ) =>
    useQuery<Types.GetCommunityUserProfileImagePresetsQuery, TError, TData>(
      variables === undefined ? ['GetCommunityUserProfileImagePresets'] : ['GetCommunityUserProfileImagePresets', variables],
      gqlFetcher<Types.GetCommunityUserProfileImagePresetsQuery, Types.GetCommunityUserProfileImagePresetsQueryVariables>(GetCommunityUserProfileImagePresetsDocument, variables),
      options
    );

useGetCommunityUserProfileImagePresetsQuery.getKey = (variables?: Types.GetCommunityUserProfileImagePresetsQueryVariables) => variables === undefined ? ['GetCommunityUserProfileImagePresets'] : ['GetCommunityUserProfileImagePresets', variables];
;

useGetCommunityUserProfileImagePresetsQuery.fetcher = (variables?: Types.GetCommunityUserProfileImagePresetsQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetCommunityUserProfileImagePresetsQuery, Types.GetCommunityUserProfileImagePresetsQueryVariables>(GetCommunityUserProfileImagePresetsDocument, variables, options);
export const CompleteCommunityUserTutorialDocument = `
    mutation CompleteCommunityUserTutorial {
  completeCommunityUserTutorial
}
    `;
export const useCompleteCommunityUserTutorialMutation = <
      TError = Types.GraphqlError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CompleteCommunityUserTutorialMutation, TError, Types.CompleteCommunityUserTutorialMutationVariables, TContext>) =>
    useMutation<Types.CompleteCommunityUserTutorialMutation, TError, Types.CompleteCommunityUserTutorialMutationVariables, TContext>(
      ['CompleteCommunityUserTutorial'],
      (variables?: Types.CompleteCommunityUserTutorialMutationVariables) => gqlFetcher<Types.CompleteCommunityUserTutorialMutation, Types.CompleteCommunityUserTutorialMutationVariables>(CompleteCommunityUserTutorialDocument, variables)(),
      options
    );
useCompleteCommunityUserTutorialMutation.fetcher = (variables?: Types.CompleteCommunityUserTutorialMutationVariables, options?: RequestInit['headers']) => gqlFetcher<Types.CompleteCommunityUserTutorialMutation, Types.CompleteCommunityUserTutorialMutationVariables>(CompleteCommunityUserTutorialDocument, variables, options);
export const BoardPostAttachmentUploadInfoDocument = `
    query BoardPostAttachmentUploadInfo($request: String!) {
  boardPostAttachmentUploadInfo(fileExtension: $request) {
    path
    preSignedUrl
  }
}
    `;
export const useBoardPostAttachmentUploadInfoQuery = <
      TData = Types.BoardPostAttachmentUploadInfoQuery,
      TError = Types.GraphqlError
    >(
      variables: Types.BoardPostAttachmentUploadInfoQueryVariables,
      options?: UseQueryOptions<Types.BoardPostAttachmentUploadInfoQuery, TError, TData>
    ) =>
    useQuery<Types.BoardPostAttachmentUploadInfoQuery, TError, TData>(
      ['BoardPostAttachmentUploadInfo', variables],
      gqlFetcher<Types.BoardPostAttachmentUploadInfoQuery, Types.BoardPostAttachmentUploadInfoQueryVariables>(BoardPostAttachmentUploadInfoDocument, variables),
      options
    );

useBoardPostAttachmentUploadInfoQuery.getKey = (variables: Types.BoardPostAttachmentUploadInfoQueryVariables) => ['BoardPostAttachmentUploadInfo', variables];
;

useBoardPostAttachmentUploadInfoQuery.fetcher = (variables: Types.BoardPostAttachmentUploadInfoQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.BoardPostAttachmentUploadInfoQuery, Types.BoardPostAttachmentUploadInfoQueryVariables>(BoardPostAttachmentUploadInfoDocument, variables, options);
export const PostBoardPostDocument = `
    mutation PostBoardPost($request: PostBoardPostRequestInput!) {
  postBoardPost(request: $request) {
    boardPostPid
  }
}
    `;
export const usePostBoardPostMutation = <
      TError = Types.GraphqlError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.PostBoardPostMutation, TError, Types.PostBoardPostMutationVariables, TContext>) =>
    useMutation<Types.PostBoardPostMutation, TError, Types.PostBoardPostMutationVariables, TContext>(
      ['PostBoardPost'],
      (variables?: Types.PostBoardPostMutationVariables) => gqlFetcher<Types.PostBoardPostMutation, Types.PostBoardPostMutationVariables>(PostBoardPostDocument, variables)(),
      options
    );
usePostBoardPostMutation.fetcher = (variables: Types.PostBoardPostMutationVariables, options?: RequestInit['headers']) => gqlFetcher<Types.PostBoardPostMutation, Types.PostBoardPostMutationVariables>(PostBoardPostDocument, variables, options);
export const SetReportBoardPostDocument = `
    mutation setReportBoardPost($request: ReportBoardPostRequestInput!) {
  reportBoardPost(request: $request)
}
    `;
export const useSetReportBoardPostMutation = <
      TError = Types.GraphqlError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.SetReportBoardPostMutation, TError, Types.SetReportBoardPostMutationVariables, TContext>) =>
    useMutation<Types.SetReportBoardPostMutation, TError, Types.SetReportBoardPostMutationVariables, TContext>(
      ['setReportBoardPost'],
      (variables?: Types.SetReportBoardPostMutationVariables) => gqlFetcher<Types.SetReportBoardPostMutation, Types.SetReportBoardPostMutationVariables>(SetReportBoardPostDocument, variables)(),
      options
    );
useSetReportBoardPostMutation.fetcher = (variables: Types.SetReportBoardPostMutationVariables, options?: RequestInit['headers']) => gqlFetcher<Types.SetReportBoardPostMutation, Types.SetReportBoardPostMutationVariables>(SetReportBoardPostDocument, variables, options);
export const SetReportBoardCommentDocument = `
    mutation setReportBoardComment($request: ReportBoardCommentRequestInput!) {
  reportBoardComment(request: $request)
}
    `;
export const useSetReportBoardCommentMutation = <
      TError = Types.GraphqlError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.SetReportBoardCommentMutation, TError, Types.SetReportBoardCommentMutationVariables, TContext>) =>
    useMutation<Types.SetReportBoardCommentMutation, TError, Types.SetReportBoardCommentMutationVariables, TContext>(
      ['setReportBoardComment'],
      (variables?: Types.SetReportBoardCommentMutationVariables) => gqlFetcher<Types.SetReportBoardCommentMutation, Types.SetReportBoardCommentMutationVariables>(SetReportBoardCommentDocument, variables)(),
      options
    );
useSetReportBoardCommentMutation.fetcher = (variables: Types.SetReportBoardCommentMutationVariables, options?: RequestInit['headers']) => gqlFetcher<Types.SetReportBoardCommentMutation, Types.SetReportBoardCommentMutationVariables>(SetReportBoardCommentDocument, variables, options);
export const DeleteBoardPostDocument = `
    mutation deleteBoardPost($boardPostPid: ID!) {
  deleteBoardPost(boardPostPid: $boardPostPid)
}
    `;
export const useDeleteBoardPostMutation = <
      TError = Types.GraphqlError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.DeleteBoardPostMutation, TError, Types.DeleteBoardPostMutationVariables, TContext>) =>
    useMutation<Types.DeleteBoardPostMutation, TError, Types.DeleteBoardPostMutationVariables, TContext>(
      ['deleteBoardPost'],
      (variables?: Types.DeleteBoardPostMutationVariables) => gqlFetcher<Types.DeleteBoardPostMutation, Types.DeleteBoardPostMutationVariables>(DeleteBoardPostDocument, variables)(),
      options
    );
useDeleteBoardPostMutation.fetcher = (variables: Types.DeleteBoardPostMutationVariables, options?: RequestInit['headers']) => gqlFetcher<Types.DeleteBoardPostMutation, Types.DeleteBoardPostMutationVariables>(DeleteBoardPostDocument, variables, options);
export const DeleteBoardCommentDocument = `
    mutation deleteBoardComment($boardCommentPid: ID!) {
  deleteBoardComment(boardCommentPid: $boardCommentPid)
}
    `;
export const useDeleteBoardCommentMutation = <
      TError = Types.GraphqlError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.DeleteBoardCommentMutation, TError, Types.DeleteBoardCommentMutationVariables, TContext>) =>
    useMutation<Types.DeleteBoardCommentMutation, TError, Types.DeleteBoardCommentMutationVariables, TContext>(
      ['deleteBoardComment'],
      (variables?: Types.DeleteBoardCommentMutationVariables) => gqlFetcher<Types.DeleteBoardCommentMutation, Types.DeleteBoardCommentMutationVariables>(DeleteBoardCommentDocument, variables)(),
      options
    );
useDeleteBoardCommentMutation.fetcher = (variables: Types.DeleteBoardCommentMutationVariables, options?: RequestInit['headers']) => gqlFetcher<Types.DeleteBoardCommentMutation, Types.DeleteBoardCommentMutationVariables>(DeleteBoardCommentDocument, variables, options);
export const BlindBoardPostDocument = `
    mutation blindBoardPost($boardPostPid: ID!) {
  blindBoardPost(boardPostPid: $boardPostPid) {
    userBlindedCount
    userBannedDayCount
    userBannedDayCountMessage
  }
}
    `;
export const useBlindBoardPostMutation = <
      TError = Types.GraphqlError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.BlindBoardPostMutation, TError, Types.BlindBoardPostMutationVariables, TContext>) =>
    useMutation<Types.BlindBoardPostMutation, TError, Types.BlindBoardPostMutationVariables, TContext>(
      ['blindBoardPost'],
      (variables?: Types.BlindBoardPostMutationVariables) => gqlFetcher<Types.BlindBoardPostMutation, Types.BlindBoardPostMutationVariables>(BlindBoardPostDocument, variables)(),
      options
    );
useBlindBoardPostMutation.fetcher = (variables: Types.BlindBoardPostMutationVariables, options?: RequestInit['headers']) => gqlFetcher<Types.BlindBoardPostMutation, Types.BlindBoardPostMutationVariables>(BlindBoardPostDocument, variables, options);
export const BlindBoardCommentDocument = `
    mutation blindBoardComment($boardCommentPid: ID!) {
  blindBoardComment(boardCommentPid: $boardCommentPid) {
    userBlindedCount
    userBannedDayCount
    userBannedDayCountMessage
  }
}
    `;
export const useBlindBoardCommentMutation = <
      TError = Types.GraphqlError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.BlindBoardCommentMutation, TError, Types.BlindBoardCommentMutationVariables, TContext>) =>
    useMutation<Types.BlindBoardCommentMutation, TError, Types.BlindBoardCommentMutationVariables, TContext>(
      ['blindBoardComment'],
      (variables?: Types.BlindBoardCommentMutationVariables) => gqlFetcher<Types.BlindBoardCommentMutation, Types.BlindBoardCommentMutationVariables>(BlindBoardCommentDocument, variables)(),
      options
    );
useBlindBoardCommentMutation.fetcher = (variables: Types.BlindBoardCommentMutationVariables, options?: RequestInit['headers']) => gqlFetcher<Types.BlindBoardCommentMutation, Types.BlindBoardCommentMutationVariables>(BlindBoardCommentDocument, variables, options);
export const BlindBoardPostAttachmentDocument = `
    mutation blindBoardPostAttachment($boardPostAttachmentId: ID!, $blindType: AttachmentBlindType!) {
  blindBoardPostAttachment(
    boardPostAttachmentId: $boardPostAttachmentId
    blindType: $blindType
  )
}
    `;
export const useBlindBoardPostAttachmentMutation = <
      TError = Types.GraphqlError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.BlindBoardPostAttachmentMutation, TError, Types.BlindBoardPostAttachmentMutationVariables, TContext>) =>
    useMutation<Types.BlindBoardPostAttachmentMutation, TError, Types.BlindBoardPostAttachmentMutationVariables, TContext>(
      ['blindBoardPostAttachment'],
      (variables?: Types.BlindBoardPostAttachmentMutationVariables) => gqlFetcher<Types.BlindBoardPostAttachmentMutation, Types.BlindBoardPostAttachmentMutationVariables>(BlindBoardPostAttachmentDocument, variables)(),
      options
    );
useBlindBoardPostAttachmentMutation.fetcher = (variables: Types.BlindBoardPostAttachmentMutationVariables, options?: RequestInit['headers']) => gqlFetcher<Types.BlindBoardPostAttachmentMutation, Types.BlindBoardPostAttachmentMutationVariables>(BlindBoardPostAttachmentDocument, variables, options);
export const UpdateBlindBoardPostOnlyDocument = `
    mutation updateBlindBoardPostOnly($boardPostPid: ID!) {
  blindBoardPostOnly(boardPostPid: $boardPostPid)
}
    `;
export const useUpdateBlindBoardPostOnlyMutation = <
      TError = Types.GraphqlError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateBlindBoardPostOnlyMutation, TError, Types.UpdateBlindBoardPostOnlyMutationVariables, TContext>) =>
    useMutation<Types.UpdateBlindBoardPostOnlyMutation, TError, Types.UpdateBlindBoardPostOnlyMutationVariables, TContext>(
      ['updateBlindBoardPostOnly'],
      (variables?: Types.UpdateBlindBoardPostOnlyMutationVariables) => gqlFetcher<Types.UpdateBlindBoardPostOnlyMutation, Types.UpdateBlindBoardPostOnlyMutationVariables>(UpdateBlindBoardPostOnlyDocument, variables)(),
      options
    );
useUpdateBlindBoardPostOnlyMutation.fetcher = (variables: Types.UpdateBlindBoardPostOnlyMutationVariables, options?: RequestInit['headers']) => gqlFetcher<Types.UpdateBlindBoardPostOnlyMutation, Types.UpdateBlindBoardPostOnlyMutationVariables>(UpdateBlindBoardPostOnlyDocument, variables, options);
export const UpdateBlindBoardCommentOnlyDocument = `
    mutation updateBlindBoardCommentOnly($boardCommentPid: ID!) {
  blindBoardCommentOnly(boardCommentPid: $boardCommentPid)
}
    `;
export const useUpdateBlindBoardCommentOnlyMutation = <
      TError = Types.GraphqlError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateBlindBoardCommentOnlyMutation, TError, Types.UpdateBlindBoardCommentOnlyMutationVariables, TContext>) =>
    useMutation<Types.UpdateBlindBoardCommentOnlyMutation, TError, Types.UpdateBlindBoardCommentOnlyMutationVariables, TContext>(
      ['updateBlindBoardCommentOnly'],
      (variables?: Types.UpdateBlindBoardCommentOnlyMutationVariables) => gqlFetcher<Types.UpdateBlindBoardCommentOnlyMutation, Types.UpdateBlindBoardCommentOnlyMutationVariables>(UpdateBlindBoardCommentOnlyDocument, variables)(),
      options
    );
useUpdateBlindBoardCommentOnlyMutation.fetcher = (variables: Types.UpdateBlindBoardCommentOnlyMutationVariables, options?: RequestInit['headers']) => gqlFetcher<Types.UpdateBlindBoardCommentOnlyMutation, Types.UpdateBlindBoardCommentOnlyMutationVariables>(UpdateBlindBoardCommentOnlyDocument, variables, options);