import Link from 'next/link';
import React, { AnchorHTMLAttributes } from 'react';

interface Props
  extends Pick<AnchorHTMLAttributes<HTMLAnchorElement>, 'onClick'> {
  children: JSX.Element;
  href: string;
  target?: string;
  rel?: string;
}

const WithHyperLinkComponent = ({
  children,
  href,
  target = '_self',
  rel = '',
  ...props
}: Props) => {
  return (
    <Link href={href}>
      <a target={target} {...props} rel={rel}>
        {children}
      </a>
    </Link>
  );
};

export default WithHyperLinkComponent;
