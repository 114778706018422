import dynamic from 'next/dynamic';
import { FunctionComponent } from 'react';
import { LottieProps, Options } from 'react-lottie';

const Lottie = dynamic(() => import('react-lottie'));

interface CustomLottieProps extends Options {
  width?: LottieProps['width'];
  height?: LottieProps['height'];
  style?: React.CSSProperties;
  isStopped?: boolean;
  isClickToPauseDisabled?: boolean;
}

const defaultOptions = {
  loop: true,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const CustomLottie: FunctionComponent<CustomLottieProps> = ({
  width,
  height,
  style,
  isStopped,
  isClickToPauseDisabled = true,
  ...customOptions
}) => {
  return (
    <Lottie
      options={{ ...defaultOptions, ...customOptions }}
      width={width}
      height={height}
      isStopped={isStopped ?? false}
      style={{ margin: 0, ...style }}
      isClickToPauseDisabled={isClickToPauseDisabled}
    />
  );
};

export default CustomLottie;
