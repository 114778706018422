import * as HackleSDK from '@hackler/javascript-sdk';

class Hackle {
  private static _hackleClient: HackleSDK.HackleClient | undefined;

  static createHackle() {
    const hackleClient = HackleSDK.createInstance(
      process.env.NEXT_PUBLIC_HACKLE_SDK_KEY || '',
      { debug: false },
    );
    this._hackleClient = hackleClient;
    return hackleClient;
  }

  static async variation(id: number) {
    return await new Promise<string | undefined>((resolve) => {
      if (!this._hackleClient) {
        return;
      } else {
        this._hackleClient.onReady(() => {
          const variation = this._hackleClient?.variation(id);
          resolve(variation);
        });
      }
    });
  }

  static getUser() {
    if (!this._hackleClient) return;
    return this._hackleClient.getUser();
  }

  static setUserId(userId: string) {
    if (!this._hackleClient) return;
    this._hackleClient.setUserId(userId);
  }

  static setDeviceId(deviceId: string) {
    if (!this._hackleClient) return;
    this._hackleClient.setDeviceId(deviceId);
  }

  static setUserProperty(key: string, value: any) {
    if (!this._hackleClient) return;
    this._hackleClient.setUserProperty(key, value);
  }

  static resetUser() {
    if (!this._hackleClient) return;
    this._hackleClient.resetUser();
  }

  static track(event: HackleSDK.HackleEvent) {
    if (!this._hackleClient) return;
    const user = this.getUser();
    this._hackleClient.track(event, user);
  }
}

export default Hackle;
