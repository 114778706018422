import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import auth from '@/api/auth';
import { User } from '@/types/Auth';
import { ApiResponse } from '@/types/common';

const useGetUserInfo = (
  queryOptions?: UseQueryOptions<ApiResponse<User>, AxiosError>,
) => {
  return useQuery<ApiResponse<User>, AxiosError>(
    ['user'],
    () => auth.getUserData(),
    queryOptions,
  );
};

export default useGetUserInfo;
