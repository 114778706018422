import dayjs from 'dayjs';

export const setTokenExpiration = (token: string): string => {
  return token + '//' + dayjs().unix();
};

export const checkTokenExpiration = (expiration: string): boolean => {
  const diffDay = dayjs().diff(dayjs(parseInt(expiration, 10) * 1000), 'hour');
  return diffDay <= 24;
};
