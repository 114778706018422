import { useRouter } from 'next/router';
import React from 'react';
import styled, { keyframes } from 'styled-components';

import { EllipsisTextStyle } from '@/components/atom/text/DefaultText';
import { zIndex } from '@/constants/zIndex';
import { Tablet } from '@/styles/layout';
import { getPageName, logger } from '@/utils/logger';

const AppDownloadCujFloating = () => {
  const router = useRouter();

  const onClickAppDownload = () => {
    logger({
      pageName: getPageName(router),
      eventName: `app_download_floating_click`,
    });
    router.push('https://abr.ge/xjermi');
  };

  return (
    <Wrapper>
      <Content>
        <div>
          <Title>대한민국 대표 비대면 진료 앱</Title>
          <Desc>앱으로 더 많은 기능을 이용해보세요</Desc>
        </div>
        <Button type="button" onClick={onClickAppDownload}>
          앱 다운로드
        </Button>
      </Content>
    </Wrapper>
  );
};

export default AppDownloadCujFloating;

const BottomUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  animation: ${BottomUp} 1s ease-in-out forwards;
  z-index: ${zIndex.BottomSticky};

  @media screen and (min-width: ${Tablet}) {
    display: none;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 12px 16px;
  border-radius: 16px 16px 0 0;
  background-color: ${({ theme }) => theme.color.gray800};
  width: 100%;
  div:first-of-type {
    margin-right: 8px;
  }
`;

const Title = styled.div`
  ${({ theme }) => theme.typography.UI14SB};
  color: ${({ theme }) => theme.color.white};
  margin-bottom: 2px;
`;

const Desc = styled.p`
  ${({ theme }) => theme.typography.UI12R};
  color: ${({ theme }) => theme.color.gray200};
  ${EllipsisTextStyle};
  -webkit-line-clamp: 1;
`;

const Button = styled.button`
  ${({ theme }) => theme.typography.UI14SB};
  color: ${({ theme }) => theme.color.white};
  padding: 6px 12px;
  width: fit-content;
  background-color: ${({ theme }) => theme.color.primary500};
  border-radius: 8px;

  &:hover {
    background-color: ${({ theme }) => theme.color.primary700};
  }
`;
