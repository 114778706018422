import { useRouter } from 'next/router';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import doctornowApp from '@/assets/lottie/doctornow-app.json';
import CustomLottieIcon from '@/components/atom/CustomLottieIcon';
import ArrowsButtonRightMin from '@/components/atom/icon/Arrow/ArrowsButtonRightMin';
import { MODAL_SHOW_PAGES } from '@/hooks/Cuj/useAppDownloadCujModal';
import useModal from '@/hooks/useModal';
import { setAppDownloadCujModalState } from '@/recoil/atoms/cuj';
import theme from '@/styles/theme';
import { getPageName, logger } from '@/utils/logger';

const AppDownloadCujModal = () => {
  const { closeModal } = useModal();
  const router = useRouter();

  const appDownloadLink = useMemo(() => {
    const page = MODAL_SHOW_PAGES.filter((item) => {
      return item.path === router.pathname;
    })[0];
    if (!page) return;
    return page.link;
  }, [router]);

  const onClickOnTheWeb = () => {
    logger({
      pageName: getPageName(router),
      eventName: `on_the_web`,
    });

    setAppDownloadCujModalState(false);
    closeModal();
  };

  const onClickAppDownload = () => {
    logger({
      pageName: getPageName(router),
      eventName: `app_download_modal_click`,
    });

    setAppDownloadCujModalState(false);
    if (appDownloadLink) {
      return router.push(appDownloadLink);
    }
  };

  return (
    <Wrapper>
      <Content>
        <Lottie>
          <CustomLottieIcon
            width={48}
            height={48}
            animationData={doctornowApp}
          />
        </Lottie>
        <Title>대한민국 대표 비대면 진료 앱</Title>
        <Desc>{'비대면 진료부터 약 처방까지!'}</Desc>
        <Button role="button" onClick={onClickAppDownload}>
          지금 설치하기
        </Button>
      </Content>
      <button type="button" onClick={onClickOnTheWeb}>
        웹으로 계속 볼게요
        <ArrowsButtonRightMin color={theme.color.white} />
      </button>
    </Wrapper>
  );
};

export default AppDownloadCujModal;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 64px);
  max-width: 296px;

  & > button {
    border: none;
    padding: 14px;

    ${({ theme }) => `
      ${theme.typography.UI14M};
      color: ${theme.color.white};
    `};

    & > svg {
      margin-left: 8px;
    }
  }
`;

const Content = styled.div`
  width: 100%;
  max-height: 254px;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding: 32px 16px 16px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.16);
  border-radius: 16px;
  margin-bottom: 14px;

  ${({ theme }) => `
    background: ${theme.color.white}
  `};
`;

const Lottie = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const Title = styled.div`
  ${({ theme }) => `
    ${theme.typography.UI18B};
    color: ${theme.color.gray700};
  `};

  margin-bottom: 8px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Desc = styled.p`
  ${({ theme }) => `
    ${theme.typography.UI15M};
    color: ${theme.color.gray500};
  `};

  margin-bottom: 16px;
  white-space: pre;
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
`;

const Button = styled.div`
  ${({ theme }) => `
    ${theme.typography.UI16SB};
    color: ${theme.color.white};
    background: ${theme.color.primary500};

    &:hover {
      background: ${theme.color.primary700};
    }
  `};
  padding: 12px 0;
  width: 100%;
  text-align: center;
  border-radius: 8px;
`;
