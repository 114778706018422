import React from 'react';

import Overlay from '@/components/molecules/Overlay';
import { BasicModalProps } from '@/types/modal';

const BasicModal = ({ body, onClickOverlay }: BasicModalProps) => {
  return (
    <Overlay index={10} onClickOverlay={onClickOverlay}>
      {body}
    </Overlay>
  );
};

export default BasicModal;
