import { useRouter } from 'next/router';
import QRCode from 'qrcode.react';
import React from 'react';
import styled from 'styled-components';

import BestQuestions from '@/components/templates/communication/Qna/BestQuestions';
import theme from '@/styles/theme';
import { getPageName, mobileLogger } from '@/utils/logger';

const Link = 'https://abr.ge/oqmbrwn';
const DefaultGlobalSideBar = () => {
  const router = useRouter();
  const onClickBanner = async () => {
    router.push(Link);
    mobileLogger({
      logger: {
        eventName: 'right_side_banner_app_download_click',
        pageName: getPageName(router),
        payload: {
          platform: 'web',
        },
      },
    });
  };

  return (
    <Wrapper>
      <DefaultBanner role="button" onClick={onClickBanner}>
        <TextWrapper>비대면 진료부터 처방약 픽업까지</TextWrapper>
        <CatchPhraseImg
          src={'https://file.doctornow.co.kr/landing/side_catchphrase.png'}
          alt="병원이 온다"
        />
        <ModelImg
          src={'https://file.doctornow.co.kr/landing/side_cf_model.png'}
          alt="이병헌 모델 이미지"
        />
      </DefaultBanner>
      <DownloadQrBox>
        <DownloadGuide>{'QR찍고\n앱 다운로드하기'}</DownloadGuide>
        <QRCode value={'https://abr.ge/ftzhdsc'} size={48} />
      </DownloadQrBox>
      <BestQuestions marginTop={32} />
    </Wrapper>
  );
};

export default DefaultGlobalSideBar;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 296px;
  min-width: 296px;
  padding: 24px 12px;
  position: sticky;
  top: 0;
  align-self: flex-start;
`;

const DefaultBanner = styled.div`
  position: relative;
  width: calc(100% - 12px);
  background: linear-gradient(225deg, #ff8701 0%, #ff7501 100%);
  border-radius: 8px;
  margin: 0 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 360px;
  cursor: pointer;
`;

const TextWrapper = styled.div`
  padding: 25px 0 10px;
  width: fit-content;
  color: ${theme.color.white};
  ${theme.typography.UI15M};
`;

const DownloadQrBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 12px;
  margin-top: 16px;
  padding-right: 12px;
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.color.gray50};
  border-bottom-style: solid;
  padding-bottom: 36px;
`;

const DownloadGuide = styled.div`
  ${({ theme }) => `
    ${theme.typography.UI17SB};
    color: ${theme.color.gray700};
    white-space: pre-wrap
  `}
`;

const CatchPhraseImg = styled.img`
  width: 225px;
  object-fit: contain;
`;

const ModelImg = styled.img`
  position: absolute;
  bottom: 0;
  width: 100%;
  object-fit: contain;
`;
