import dayjs, { Dayjs } from 'dayjs';
import showdown from 'showdown';

import { INFLUENZA, INFLUENZA_DEFAULT_PLACEHOLDER } from '@/constants/f2f';
import { UserAddressType } from '@/models/common';
import { NonF2fDoctor } from '@/models/nonBenefit';
import theme from '@/styles/theme';
import {
  AddressType,
  AnswererType,
  AttachmentBlindType,
  AuthorType,
  DayOfWeekType,
  GetQuestionDetailQuery,
  HospitalOperatingStatus,
  MedicineType,
  QuestionAnsweredStatus,
} from '@/types/bff/gql-schema';
import { Nullable, ROUTES } from '@/types/common';
import { BOARD_POST_TAG_TYPE } from '@/types/community';
import {
  DepartmentReviewFilter,
  DiagnosisState,
  HospitalDoctorResponse,
  HospitalResponse,
  HospitalReviewFilter,
  HospitalReviewMetricResponse,
  LocalHospitalBusinessJson,
  MedicalCategoryVo,
  ReviewSortType,
  SearchHospitalVo,
} from '@/types/hospital';
import { QnaAttachment, QnaDetailVo } from '@/types/qna';
import { KeywordSearchMagazineVo } from '@/types/search';
import { stringifyToArray } from '@/utils/url';

export const displayedAt = (date: number) => {
  const milliSeconds = dayjs().diff(dayjs(date));
  const seconds = milliSeconds / 1000;
  const minutes = seconds / 60;
  const hours = minutes / 60;
  const days = hours / 24;

  if (days >= 7) {
    return dayjs(date).format('YYYY.MM.DD');
  }
  if (hours < 1) {
    return `방금 전`;
  } else if (hours < 24) {
    return `${Math.floor(hours)}시간 전`;
  } else {
    return `${Math.floor(days)}일 전`;
  }
};

export const absoluteDate = (date: number) => dayjs(date).format('YYYY.MM.DD');

export const absoluteTime = (date: number) =>
  dayjs(date).format('YYYY-MM-DDThh:mm:ssTZD|PTDHMS');

// 1000 -> 1,000
export const getCurrencyFormat = (num: number) =>
  num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const getUserAge = (birth: string) => {
  const getYear = () => dayjs().year();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return getYear() - Number(birth.match(/^(\d{4})-/)![1]!) + 1;
};
export const formatShareUrl = (url: string): string => {
  return `${process.env.NEXT_PUBLIC_DOMAIN}${url}`;
};

export const refreshedTimeAt = (date: Dayjs) => {
  const milliSeconds = dayjs().diff(dayjs(date));
  const seconds = milliSeconds / 1000;
  const minutes = seconds / 60;
  if (minutes < 1) {
    return '방금 전';
  } else if (minutes >= 4) {
    return '4분 전';
  } else {
    return `${Math.floor(minutes)}분 전`;
  }
};

export const sortImageBlindTypeFormat = (attachments: Array<QnaAttachment>) => {
  /* 리스트, 상세페이지 사진 노출
   * NONE -> DIMMED -> BLINDE 순
   * 질문 리스트에서 이미지 노출 추가되면서 최대한 공개된 사진 위주로 보여주기위해
   * 중간에 DIMMED 까지 정렬함
   */
  return attachments
    .filter((x) => x.blindType === AttachmentBlindType.None)
    .concat(
      attachments.filter((x) => x.blindType === AttachmentBlindType.Dimmed),
    )
    .concat(
      attachments.filter((x) => x.blindType === AttachmentBlindType.Blinded),
    );
};

export const getQnaAnswerStatusLabel = (status: QuestionAnsweredStatus) => {
  switch (status) {
    case QuestionAnsweredStatus.Answered:
      return '답변완료';
    case QuestionAnsweredStatus.Answering:
      return '답변중';
    default:
      return '확인중';
  }
};

export const getQnaAnswerStatus = (status: QuestionAnsweredStatus) => {
  switch (status) {
    case QuestionAnsweredStatus.Checking:
      return '질문 확인 중';
    case QuestionAnsweredStatus.Answering:
      return '답변 작성 중';
    default:
      return '답변완료';
  }
};

export const getQnaAnswerStatusInDetail = (status: QuestionAnsweredStatus) => {
  switch (status) {
    case QuestionAnsweredStatus.Checking:
      return '의료진이 질문을 확인하기 전이에요.';
    case QuestionAnsweredStatus.Answering:
      return '의료진이 답변을 작성하고 있어요.';
  }
};

export const getQndAnswererMedicalStaffType = (
  type: AuthorType | AnswererType,
  name: string,
) => {
  switch (type) {
    case 'NURSE':
      return '닥터나우 의료진';
    case 'AI':
      return `${name}`;
    default:
      return `${name} 선생님`;
  }
};

export const getSortTypeName = (sort: ReviewSortType) => {
  switch (sort) {
    case ReviewSortType.RECENT:
      return '최신순';
    case ReviewSortType.LOW:
      return '별점 낮은 순';
    case ReviewSortType.HIGH:
      return '별점 높은 순';
  }
};

export const isNumeric = (text: string) => /^[0-9]+$/.test(text);

export const getRatingTitle = (rating: number): string => {
  const ratingTitle: Record<number, string> = {
    5: '매우 만족',
    4: '만족',
    3: '보통',
    2: '불만족',
    1: '매우 불만족',
  };
  return ratingTitle[`${rating}`];
};

export const getDepartmentReviewFilter = (
  departmentFilter: DepartmentReviewFilter,
): HospitalReviewFilter => {
  return {
    count: departmentFilter.count,
    id: Number(departmentFilter.diagnosisTypeId),
    name: departmentFilter.name,
  };
};

export const getDoctorReviewFilter = (
  doctorFilter: HospitalDoctorResponse,
): HospitalReviewFilter => {
  return {
    count: doctorFilter.reviewMetric.totalCount,
    id: Number(doctorFilter.id),
    name: doctorFilter.name,
  };
};

export const getDiagnosisStateOfOperatingStatus = (
  operationgStatus: HospitalOperatingStatus,
): DiagnosisState => {
  switch (operationgStatus) {
    case HospitalOperatingStatus.Open:
      return DiagnosisState.On;
    case HospitalOperatingStatus.Closed:
      return DiagnosisState.Off;
    case HospitalOperatingStatus.Lunch:
      return DiagnosisState.Lunch;
    case HospitalOperatingStatus.DayOff:
      return DiagnosisState.DayOff;
    case HospitalOperatingStatus.Unknown:
    default:
      return DiagnosisState.Unknown;
  }
};

export const getDayOfTheWeekToTitle = (dayOfWeek: DayOfWeekType) => {
  switch (dayOfWeek) {
    case DayOfWeekType.Monday:
      return '월';
    case DayOfWeekType.Tuesday:
      return '화';
    case DayOfWeekType.Wednesday:
      return '수';
    case DayOfWeekType.Thursday:
      return '목';
    case DayOfWeekType.Friday:
      return '금';
    case DayOfWeekType.Saturday:
      return '토';
    case DayOfWeekType.Sunday:
      return '일';
    case DayOfWeekType.Holiday:
      return '공휴일';
  }
};

export const getDayOfTheWeekToColor = (dayOfWeek: DayOfWeekType) => {
  switch (dayOfWeek) {
    case DayOfWeekType.Holiday:
    case DayOfWeekType.Sunday:
      return theme.color.red700;
    case DayOfWeekType.Saturday:
      return theme.color.blue700;
    case DayOfWeekType.Monday:
    case DayOfWeekType.Tuesday:
    case DayOfWeekType.Wednesday:
    case DayOfWeekType.Thursday:
    case DayOfWeekType.Friday:
      return theme.color.gray800;
  }
};

export const formatKilometer = (meter: number) => meter / 1000;

export const formatKilometerByMeter = (meter: number) => {
  if (meter >= 1000) {
    const result = (meter / 1000).toFixed(1);
    return result + 'km';
  } else {
    return meter + 'm';
  }
};

export const getReviewScore = (number: number, numberLength = 2) => {
  const score = Math.floor(number * 10) / 10;
  return score.toPrecision(numberLength);
};

export const getBoardPostTagLabel = (type: BOARD_POST_TAG_TYPE) => {
  switch (type) {
    case BOARD_POST_TAG_TYPE.RECOMMENDED:
      return '추천';
    case BOARD_POST_TAG_TYPE.POPULAR:
    default:
      return '인기';
  }
};

export const getDiagnosisStateText = (state: DiagnosisState) => {
  switch (state) {
    case DiagnosisState.On:
      return '진료중';
    case DiagnosisState.Off:
      return '진료종료';
    case DiagnosisState.Lunch:
      return '휴게시간';
    case DiagnosisState.DayOff:
      return '휴무';
    case DiagnosisState.Unknown:
      return '확인필요';
  }
};

export const getUserAddressTypeTitle = (addressType: AddressType) => {
  switch (addressType) {
    case AddressType.Home:
      return '집';
    case AddressType.Company:
      return '회사';
    case AddressType.Others:
      return '기타';
    default:
      return '기타';
  }
};

export const getUserAddressType = (
  addressType: AddressType,
): UserAddressType => {
  switch (addressType) {
    case AddressType.Home:
      return UserAddressType.Home;
    case AddressType.Company:
      return UserAddressType.Company;
    case AddressType.Others:
      return UserAddressType.Others;
    default:
      return UserAddressType.Others;
  }
};

export const parseDashTel = (str: Nullable<string>) => {
  if (typeof str !== 'string') return '';

  str = str.replace(/[^0-9]/g, '');

  if (str.indexOf('82') == 0) {
    return str.replace(/(^82)(2|\d{2})(\d+)?(\d{4})$/, '+$1-$2-$3-$4'); // +82
  } else if (str.indexOf('1') == 0) {
    return str.replace(/(^1\d{3})(\d{4})$/, '$1-$2'); // 1588, 1566, 1677, ...
  }
  return str.replace(
    /(^02|^0501|^0502|^0503|^0504|^0505|^0506|^0507|^0508|^0509|^0\d{2})(\d+)?(\d{4})$/,
    '$1-$2-$3',
  ); // 02/0501/0502/0503/0504/0505/0506/0507/0508/0509/010/011/031
};

export const getFormattedReviewTotalCount = (totalCount: number): string => {
  return `${totalCount}`;
};

function capitalize(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}

export const createHospitalLdJson = (
  hospital?: HospitalResponse,
  reviewData?: HospitalReviewMetricResponse,
  publicId?: string,
) => {
  const foundHospitalImage = hospital?.profileImages.find(
    (image) => !image.url.includes('hospital_default'),
  );

  const localBusinessJson: LocalHospitalBusinessJson = {
    '@context': 'https://schema.org',
    '@type': 'MedicalBusiness',
  };

  if (hospital?.name) {
    localBusinessJson.name = `${hospital.name}`;
  }

  if (foundHospitalImage) {
    localBusinessJson.image = foundHospitalImage.url;
  }

  if (publicId) {
    localBusinessJson.url = `${process.env.NEXT_PUBLIC_DOMAIN}${ROUTES.HOSPITAL.DETAIL.path}${publicId}`;
  }

  if (!!reviewData?.averageRating && !!reviewData?.totalCount) {
    localBusinessJson.aggregateRating = {
      '@type': 'AggregateRating',
      ratingValue: `${reviewData.averageRating}`,
      reviewCount: `${reviewData.totalCount}`,
    };
  }

  if (hospital?.roadAddress || hospital?.jibunAddress) {
    const address = (hospital?.roadAddress || hospital?.jibunAddress) ?? '';
    const streetAddress = `${hospital?.roadAddress || hospital?.jibunAddress}${
      hospital?.detailAddress ? ` ${hospital.detailAddress}` : ``
    }`;

    const [region, locality] = address.split(' ');

    localBusinessJson.address = {
      '@type': 'PostalAddress',
      streetAddress,
      addressCountry: '대한민국',
      ...(region && locality
        ? { addressRegion: region, addressLocality: locality }
        : {}),
    };
  }

  if (hospital?.latitude && hospital.longitude) {
    localBusinessJson.geo = {
      '@type': 'GeoCoordinates',
      latitude: `${hospital.latitude}`,
      longitude: `${hospital.longitude}`,
    };
  }

  if (hospital?.tel) {
    localBusinessJson.telephone = parseDashTel(hospital.tel);
  }

  if (hospital?.duties) {
    const validDuties = hospital.duties
      .filter((duty) => !!duty.openTime && !!duty.closeTime && !duty.isClosed)
      .map((duty) => ({
        '@type': 'OpeningHoursSpecification' as const,
        dayOfWeek: capitalize(duty.dayOfWeek),
        opens: duty.openTime ?? '',
        closes: duty.closeTime ?? '',
      }));
    if (validDuties.length > 0) {
      localBusinessJson.openingHoursSpecification = validDuties;
    }
  }

  if (hospital?.partnershipTemplate) {
    const departmentList = hospital?.departmentGroups ?? [];
    localBusinessJson.description = `${
      hospital?.regionByDepth.eupMyeonDongName
    } ${hospital?.name}에서, ${departmentList
      .slice(0, 3)
      .map((departmentGroup) => departmentGroup.departmentGroupName)
      .join(
        ', ',
      )} 진료를 바로 예약할 수 있어요. 76,614개 병원이 모인 닥터나우에서 내게 딱 맞는 병원을 찾아보세요!`;
    if (departmentList.length > 0) {
      localBusinessJson.department = `${departmentList
        .map((department) => department.departmentGroupName)
        .join(', ')}`;
    }
  } else {
    const departmentList = hospital?.departmentsV2 ?? [];
    localBusinessJson.description = `${
      hospital?.regionByDepth.eupMyeonDongName
    } ${hospital?.name}에서, ${departmentList
      .slice(0, 3)
      .map((department) => department.departmentName)
      .join(
        ', ',
      )} 진료를 바로 예약할 수 있어요. 76,614개 병원이 모인 닥터나우에서 내게 딱 맞는 병원을 찾아보세요!`;

    if (departmentList.length > 0) {
      localBusinessJson.department = `${departmentList
        .map((department) => department.departmentName)
        .join(', ')}`;
    }
  }

  return localBusinessJson;
};

export const createSearchHospitalLdJson = (
  searchedHospitals: SearchHospitalVo[],
  keyword?: string,
) => {
  const hospitals = searchedHospitals.slice(0, 20);
  const totalCount = searchedHospitals[0]?.hospitalTotalCount ?? 0;

  const title = keyword
    ? `${keyword} 인기 병원 TOP10 - 닥터나우`
    : '인기 병원 TOP10 - 닥터나우';

  const description = keyword
    ? `인기 병원을 바로 예약할 수 있어요. TOP10을 포함한 ${keyword} ${totalCount}개 중 나에게 꼭 맞는 병원을 선택해 보세요!`
    : '인기 병원을 바로 예약할 수 있어요. TOP10을 포함한 76,614개 중 나에게 꼭 맞는 병원을 선택해 보세요!';

  const canonical = keyword
    ? `${process.env.NEXT_PUBLIC_DOMAIN}${ROUTES.HOSPITAL.SEARCH.path}?keyword=${keyword}`
    : `${process.env.NEXT_PUBLIC_DOMAIN}${ROUTES.HOSPITAL.SEARCH.path}`;

  return [
    {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      name: '닥터나우',
      url: `${process.env.NEXT_PUBLIC_DOMAIN}`,
      logo: `${process.env.NEXT_PUBLIC_MAIN_OG_IMAGE}`,
      contactPoint: [
        {
          '@type': 'ContactPoint',
          email: 'contact@doctornow.co.kr',
          contactType: 'customer service',
        },
      ],
    },
    {
      '@context': 'https://schema.org',
      '@type': 'WebSite',
      url: `${process.env.NEXT_PUBLIC_DOMAIN}`,
      potentialAction: {
        '@type': 'SearchAction',
        target: `${process.env.NEXT_PUBLIC_DOMAIN}${ROUTES.HOSPITAL.SEARCH.path}?keyword={keyword}`,
        'query-input': 'required name=keyword',
      },
    },
    {
      '@context': 'https://schema.org',
      '@type': 'ItemList',
      name: title,
      description: description,
      url: canonical,
      itemListElement: hospitals.map((hospital, i) => ({
        '@type': 'ListItem',
        position: `${i + 1}`,
        name: hospital.hospitalName,
        url: `${process.env.NEXT_PUBLIC_DOMAIN}${ROUTES.HOSPITAL.DETAIL.path}${hospital.hospitalPublicId}`,
      })),
    },
  ];
};

export const nonBenefitSeoLdJson = (params: {
  doctors: NonF2fDoctor[];
  faqs: KeywordSearchMagazineVo[];
  canonical: string;
  address: string;
}) => {
  const { doctors, canonical, faqs } = params;
  const converter = new showdown.Converter();

  const ldJson: Record<string, unknown>[] = [
    {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      name: '닥터나우',
      url: `${process.env.NEXT_PUBLIC_DOMAIN}`,
      logo: `${process.env.NEXT_PUBLIC_MAIN_OG_IMAGE}`,
      contactPoint: [
        {
          '@type': 'ContactPoint',
          email: 'contact@doctornow.co.kr',
          contactType: 'customer service',
        },
      ],
    },
    {
      '@context': 'https://schema.org',
      '@type': 'WebSite',
      url: `${process.env.NEXT_PUBLIC_DOMAIN}`,
      potentialAction: {
        '@type': 'SearchAction',
        target: `${process.env.NEXT_PUBLIC_DOMAIN}${ROUTES.NON_BENEFIT.HOME.path}?treatmentMedicineUnitId={id}`,
        'query-input': 'required name=id',
      },
    },
    {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: faqs.map((faq) => {
        const convertedText = converter.makeHtml(faq.content);

        return {
          '@type': 'Question',
          name: faq.title,
          acceptedAnswer: {
            '@type': 'Answer',
            text: convertedText,
          },
        };
      }),
    },
  ];

  if (doctors.length > 0) {
    ldJson.push({
      '@context': 'https://schema.org',
      '@type': 'ItemList',
      url: canonical,
      itemListElement: doctors.map((doctor, i) => ({
        '@type': 'ListItem',
        position: `${i + 1}`,
        name: doctor.doctorDisplayName,
        url: `${process.env.NEXT_PUBLIC_DOMAIN}${ROUTES.DOCTOR.DETAIL.path}/${doctor.doctorId}`,
      })),
    });
  }

  return ldJson;
};

export const getAnswerDetail = (answer: QnaDetailVo['answers'][0]) => {
  const { header, content, footer } = answer;
  if (!header && !footer) {
    return content;
  }

  let temp = content;

  if (header) {
    temp = header + '\n\n' + temp;
  }

  if (footer) {
    temp = temp + '\n\n' + footer;
  }

  return temp;
};

export const getNearQuestion = (
  questionPid: string,
  nearQuestions: GetQuestionDetailQuery['nearSimpleQuestions'],
) => {
  // [0] 부터 최신글
  if (nearQuestions.length === 3) {
    // [0] 다음글, [1] 현재글, [2] 이전글
    return {
      prev: nearQuestions[2].questionPid,
      next: nearQuestions[0].questionPid,
    };
  }

  if (nearQuestions.length === 2) {
    // [0] 현재글, [1] 이전글 (현재글이 최신)
    if (nearQuestions[0].questionPid === questionPid) {
      return {
        prev: nearQuestions[1].questionPid,
        next: undefined,
      };
    }
    // [0] 다음글, [1] 현재글 (현재글이 첫글)
    if (nearQuestions[1].questionPid === questionPid) {
      return {
        prev: undefined,
        next: nearQuestions[0].questionPid,
      };
    }
  }
  return {
    prev: undefined,
    next: undefined,
  };
};

export const getDetailSymptomText = (type?: string): string | undefined => {
  if (!type) {
    return undefined;
  }
  switch (type) {
    case INFLUENZA:
      return INFLUENZA_DEFAULT_PLACEHOLDER;
    default:
      return `${type} 상담`;
  }
};

export const isEventLabel = (category: MedicalCategoryVo) => {
  const EventLabel = '이벤트';
  return category.label === EventLabel;
};

export const getCommonFilterQueryString = (
  commonFilter: Record<string, boolean>,
): string => {
  const selectedCommonFilterKeys = Object.entries(commonFilter).reduce(
    (acc, cur) => {
      const [key, value] = cur;
      if (value) {
        acc.push(key);
      }

      return acc;
    },
    [] as string[],
  );

  return stringifyToArray(selectedCommonFilterKeys);
};

export const getMedicineType = (type: MedicineType) => {
  switch (type) {
    case MedicineType.OrientalDoctor:
      return '한의사';
    case MedicineType.Doctor:
    default:
      return '의사';
  }
};

export const formatPriceComma = (x?: string | number) => {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') ?? '0';
};

export const getSatisfactionText = (score: number) => {
  const satisfactionTexts = [
    '',
    '매우 별로에요',
    '별로에요',
    '그저 그래요',
    '만족해요',
    '매우 만족해요',
  ];

  return satisfactionTexts[`${score}`] ?? satisfactionTexts[0];
};
