import { DehydratedState } from '@tanstack/react-query';
import { NextPage } from 'next';
import { AppProps } from 'next/app';
import React, { PropsWithChildren } from 'react';

export type CustomPage<P = Record<string, unknown>, IP = P> = NextPage<
  P,
  IP
> & {
  Layout?: React.FC<PropsWithChildren>;
  Sidebar?: React.FC<PropsWithChildren>;
};

export interface CustomPageProps {
  client: Client;
}

export type CustomAppProps = AppProps<
  {
    dehydratedState: DehydratedState;
    userAgent: string;
  } & CustomPageProps
> & {
  Component: CustomPage;
};

export enum ClientType {
  MOBILE_WEBVIEW = 'mobile-webview',
  MOBILE_WEB = 'mobile-web',
  WEB = 'web', // pc
}

export interface Client {
  clientType: ClientType;
  isWebView: boolean;
  isMobile: boolean;
  isIOS: boolean;
  isAndroid: boolean;
}
