import { useState } from 'react';
import styled, { keyframes } from 'styled-components';

import { Toast } from '@/types/Toast';

interface Props {
  toast: Toast;
  index: number;
}
const ToastMobileItem = ({ toast, index }: Props) => {
  const [unmount, setUnmount] = useState<boolean>(false);
  const animationEnd = () => {
    setTimeout(() => {
      setUnmount(true);
    }, toast.props.visibleTime ?? 2000);
  };

  return (
    <ToastItemWrapper
      key={`toast-${toast.id}`}
      index={index + 1}
      unmount={unmount}
      onAnimationEnd={animationEnd}
    >
      {toast.props.message}
    </ToastItemWrapper>
  );
};

export default ToastMobileItem;

const BottomUp = keyframes`
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
`;

const TopDown = (index: number) => keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(calc((100% + 32px) * ${index + 1}));
  }
`;

const ToastItemWrapper = styled.div<{ unmount: boolean; index: number }>`
  ${({ theme }) => `
    ${theme.typography.UI14M};
    color: ${theme.color.white};
    background: ${theme.color.gray800};
    `};
  width: 100%;
  padding: 14px 16px;
  margin-top: 16px;
  border-radius: 8px;
  text-align: left;
  animation: ${({ unmount, index }) => (unmount ? TopDown(index) : BottomUp)}
    0.4s ease-in-out forwards;
  white-space: pre-wrap;
`;
