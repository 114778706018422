import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import AppDownloadCujModal from '@/components/common/Modal/Cuj/AppDownloadCujModal';
import useModal from '@/hooks/useModal';
import { appDownloadCujModalState } from '@/recoil/atoms/cuj';
import { MODAL_TYPE } from '@/types/modal';

export const MODAL_SHOW_PAGES = [
  { path: '/', link: 'https://abr.ge/fgbb8h' },
  { path: '/content/qna/realtime', link: 'https://abr.ge/xl6mno' },
  { path: '/content/qna/[id]', link: 'https://abr.ge/y2mlu1' },
  { path: '/hospitals', link: 'https://abr.ge/1totxt' },
  { path: '/hospitals/search', link: 'https://abr.ge/a1rxxv' },
  { path: '/hospitals/[hospitalPid]', link: 'https://abr.ge/cmgskv' },
  { path: '/content/magazine', link: 'https://abr.ge/dt8fv1' },
  { path: '/content/magazine/[id]', link: 'https://abr.ge/054rrl' },
  { path: '/content/faq/[id]', link: 'https://abr.ge/rgoppq' },
  { path: '/search', link: 'https://abr.ge/yurdtxu' },
  { path: '/content/qna/ai-answer/[pid]', link: 'https://abr.ge/mwmjor' },
];

const useAppDownloadCujModal = () => {
  const { showModal, closeModal } = useModal();
  const router = useRouter();

  const [_isShow, _setIsShow] = useState(false);
  const isAppDownloadCujModalShow = useRecoilValue(appDownloadCujModalState);
  const modalShowPaths = MODAL_SHOW_PAGES.reduce((acc, cur) => {
    return [...acc, cur.path];
  }, [] as string[]);

  const isShowPath = modalShowPaths.includes(router.pathname);

  useEffect(() => {
    if (_isShow && isShowPath && isAppDownloadCujModalShow) {
      showModal({
        type: MODAL_TYPE.BASIC,
        props: {
          body: <AppDownloadCujModal />,
        },
      });
    }
  }, [_isShow, isShowPath, isAppDownloadCujModalShow]);

  useEffect(() => {
    const close = () => {
      closeModal();
    };

    window.addEventListener('popstate', close);

    return () => {
      window.removeEventListener('popstate', close);
    };
  });

  return {
    setIsCujModalShow: _setIsShow,
  };
};

export default useAppDownloadCujModal;
