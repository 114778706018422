import { infoReport } from '@/utils/report';

import { getErrorObject } from './error';

class Pixel {
  static async initialize() {
    try {
      const { default: ReactPixel } = await import('react-facebook-pixel');
      ReactPixel.init(process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID ?? '');
      ReactPixel.pageView();
    } catch (error) {
      infoReport('Pixel:initialize > error', { error }, getErrorObject(error));
    }
  }

  static async trackCustom(event: string, data?: any) {
    try {
      const { default: ReactPixel } = await import('react-facebook-pixel');
      await ReactPixel.trackCustom(event, data);
    } catch (error) {
      infoReport('Pixel:track > error', { error }, getErrorObject(error));
    }
  }
}

export default Pixel;
