import { atom } from 'recoil';
import { getRecoil, setRecoil } from 'recoil-nexus';
import { recoilPersist } from 'recoil-persist';

import { User } from '@/types/Auth';

const { persistAtom } = recoilPersist();

export const userState = atom({
  key: 'userState',
  default: {},
  effects_UNSTABLE: [persistAtom],
});

export const getUserState = () => {
  return getRecoil(userState);
};

export const setUserState = (userData: User) => {
  setRecoil(userState, userData);
};
