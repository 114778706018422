import { atom } from 'recoil';
import { getRecoil, setRecoil } from 'recoil-nexus';

import { localStorageEffect, sessionStorageEffect } from '@/utils/atom';
import { isWebView } from '@/utils/mobile/RNPlugins';

export type TokenState = string | undefined | null;

export type ReturnUrlState = string;

export const tokenState = atom<TokenState>({
  key: 'tokenState',
  default: null,
  effects: [
    isWebView() ? sessionStorageEffect('token') : localStorageEffect('token'),
  ],
});

export const UrlState = atom<ReturnUrlState>({
  key: 'returnUrl',
  default: '',
  effects: [sessionStorageEffect('url')],
});

export const getAuthToken = () => {
  return getRecoil(tokenState);
};

export const setAuthToken = (token = '') => {
  setRecoil(tokenState, token);
};
