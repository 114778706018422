// ref: https://github.com/jpalumickas/use-window-focus
import { useEffect, useState } from 'react';

const hasFocus = () => typeof document !== 'undefined' && document.hasFocus();

const useWindowFocus = () => {
  const [focused, setFocused] = useState(hasFocus); // Focus for first render

  useEffect(() => {
    setFocused(hasFocus()); // Focus for additional renders

    const onFocus = () => setFocused(true);
    const onFocusOut = () => setFocused(false);

    // AOS focusin / focusout
    window.addEventListener('focusin', onFocus);
    window.addEventListener('focusout', onFocusOut);
    // IOS focus / blur
    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onFocusOut);
    return () => {
      window.removeEventListener('focusin', onFocus);
      window.removeEventListener('focusout', onFocusOut);
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onFocusOut);
    };
  }, []);

  return focused;
};

export default useWindowFocus;
