import { useRouter } from 'next/router';

import { CommonLoggerEventName } from '@/constants/logs/common';
import { ClickLoggerParams, Payload } from '@/types/log';
import Firebase from '@/utils/firebase';
import { getPageName, mobileLogger } from '@/utils/logger';
import { addActionForRum } from '@/utils/report';

const useLogger = () => {
  const router = useRouter();

  const pageViewLog = (userAgent: string, payloadParams?: Payload) => {
    const pageName = getPageName(router);
    const envParameters = Firebase.getUserServiceEnvironmentProperty(userAgent);

    /* DD는 페이지뷰 자동 수집이라 GA만 수집*/
    mobileLogger({
      logger: {
        eventName: CommonLoggerEventName.PageView,
        pageName,
        payload: {
          ...(router.query as Payload),
          ...payloadParams,
          ...envParameters,
        },
      },
    });
  };

  const log = (params: ClickLoggerParams) => {
    const { eventName, payload } = params;
    const pageName = getPageName(router);

    mobileLogger({
      logger: {
        eventName,
        pageName,
        payload,
      },
    });

    addActionForRum(eventName, payload);
  };

  return {
    log: log,
    pageView: pageViewLog,
  };
};

export default useLogger;

export type UseLogger = ReturnType<typeof useLogger>;
