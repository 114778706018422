import { useRecoilState } from 'recoil';

import { modalState } from '@/recoil/atoms/common/modal';
import { MODAL_TYPE, ModalVo } from '@/types/modal';

export type AddModalProps = Omit<ModalVo, 'index'>;

const useModal = () => {
  const [modals, setModals] = useRecoilState(modalState);

  const addModal = (modal: AddModalProps) => {
    setModals((oldModals) => [
      ...oldModals,
      { ...modal, index: oldModals.length + 1 },
    ]);
  };

  const showModal = ({ type, props }: AddModalProps) => {
    switch (type) {
      case MODAL_TYPE.BASIC:
      default:
        addModal({ type, props });
        break;
    }
  };

  const closeModal = () => {
    const tempModals = [...modals];
    tempModals.pop();
    setModals(tempModals);
  };

  return {
    isModalOpen: modals.length > 0,
    showModal,
    closeModal,
  };
};

export default useModal;
