import styled from 'styled-components';

export const OverlayContainer = styled.div<{
  index: number;
  backgroundColor?: string;
}>`
  z-index: ${({ index }) => index + 300};
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;
