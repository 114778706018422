import styled from 'styled-components';

export const DefaultButtonStyled = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  font: inherit;
  color: inherit;
  cursor: pointer;
`;

export const LabelButton = styled.button`
  border: none;
  background-color: ${({ theme }) => theme.color.white};
  padding: 14px 12px;
  border-radius: 8px;

  &:hover {
    background-color: ${({ theme }) => theme.color.gray30};
  }

  &:active {
    background-color: ${({ theme }) => theme.color.gray50};
  }
`;

export const PrimaryButton = styled(DefaultButtonStyled)`
  ${({ theme }) => theme.typography.UI16R};
  width: 100%;
  padding: 10px 16px;
  background-color: ${({ theme }) => theme.color.primary500};
  color: ${({ theme }) => theme.color.white};
  border-radius: 8px;
`;

export const FloatingActionButton = styled(DefaultButtonStyled)`
  z-index: 10;
  position: fixed;
`;
