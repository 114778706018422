import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

export interface neverSeeAgainType {
  [key: string]: boolean;
}
const { persistAtom } = recoilPersist();

export const neverSeeAgainState = atom<neverSeeAgainType>({
  key: 'neverSeeAgainState',
  default: {},
  effects_UNSTABLE: [persistAtom],
});
