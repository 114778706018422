/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import { set } from 'lodash';

import { getAuthToken, setAuthToken } from '@/recoil/atoms/common/auth';
import { SuccessBffApiResponse, isServer } from '@/types/common';
import { GraphqlError } from '@/types/error';
import { GraphqlErrors } from '@/utils/error';
import { checkTokenExpiration, setTokenExpiration } from '@/utils/token';

import { isWebView } from './mobile/RNPlugins';

export const BFF_BASE_URL = `${process.env.NEXT_PUBLIC_BFF_API_ENDPOINT}`;
const API_BASE_URL = `${process.env.NEXT_PUBLIC_API_ENDPOINT}`;

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  // 서버서사이드에서 실행될 경우 로컬스토리지, 스토어가 없으니 그냥 config 반환
  if (isServer) {
    return config;
  }

  const token = getAuthToken();
  const controller = new AbortController();

  if (token) {
    if (isWebView()) {
      config.headers!.Authorization = `Bearer ${token}`;
      return config;
    }
    const [accessToken, expirationTime] = token.split('//');

    //토큰 만료 기간 확인 후 (당일 발급된 토큰)
    if (checkTokenExpiration(expirationTime)) {
      // 헤더에 토큰 추가
      config.headers!.Authorization = `Bearer ${accessToken}`;
      // 토큰 만료 시
    } else {
      setAuthToken();
      config.headers!.Authorization = '';
      // 요청 취소
      controller.abort();

      return {
        ...config,
        signal: controller.signal,
      };
    }
  }

  return config;
};

const onRequestError = (requestError: AxiosError): Promise<AxiosError> => {
  return Promise.reject(requestError);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  if (isServer) {
    return response;
  }

  if ('errors' in response.data) {
    return response;
  }

  if (isWebView()) {
    return response;
  }

  const token = getAuthToken();
  if (token) {
    const accessToken = token.split('//')[0];

    const newToken = setTokenExpiration(accessToken);

    setAuthToken(newToken);
  }
  return response;
};

const onResponseError = async (responseError: AxiosError) => {
  return Promise.reject(responseError);
};

//restApi instance
export const instance = axios.create({
  headers: { 'content-type': 'application/json' },
  baseURL: API_BASE_URL,
});

export const RestApi = {
  get: <ResponseType>(
    url: string,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<ResponseType>> => instance.get(url, options),
  post: <ParamType, ResponseType>(
    url: string,
    param?: ParamType,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<ResponseType>> => instance.post(url, param, options),
  put: <ParamType, ResponseType>(
    url: string,
    param?: ParamType,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<ResponseType>> => instance.put(url, param, options),
  delete: <ResponseType>(
    url: string,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<ResponseType>> => instance.delete(url, options),
};

instance.interceptors.request.use(onRequest, onRequestError);
instance.interceptors.response.use(onResponse, onResponseError);

export const axiosInstance: AxiosInstance = axios.create({
  headers: { 'content-type': 'application/json' },
  timeout: 30000,
});

axiosInstance.interceptors.request.use(onRequest, onRequestError);
axiosInstance.interceptors.response.use(onResponse, onResponseError);

export const removeAuthorization = () => {
  setAuthToken();
  axiosInstance.defaults.headers.common['Authorization'] = '';
};

export const preSignedUrlInstance = axios.create({
  headers: { 'Content-Type': 'multipart/form-data' },
});

export const preSignedUrlFetch = (baseURL = API_BASE_URL) => {
  preSignedUrlInstance.defaults.baseURL = baseURL;
  return preSignedUrlInstance;
};
//graphQL fetch
export const fetch = (baseURL = API_BASE_URL) => {
  axiosInstance.defaults.baseURL = baseURL;
  return axiosInstance;
};

const newAbortSignal = (time = 30000) => {
  const abortController = new AbortController();
  setTimeout(() => abortController.abort(), time || 0);

  return abortController.signal;
};

export const gqlFetcher = <TData, TVariables>(
  query: string,
  variables?: TVariables,
  options?: RequestInit['headers'],
) => {
  return async () => {
    const response = await fetch(`${BFF_BASE_URL}/graphql`).post<
      SuccessBffApiResponse<TData>
    >(
      '',
      {
        query,
        variables,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          ...(options as { [key: string]: string }),
        },
        signal: newAbortSignal(),
      },
    );

    const _errors = (
      response as unknown as { data: { errors?: Array<GraphqlErrors> } }
    ).data.errors;

    if (_errors) {
      const { message, extensions } = _errors[0] || {};

      if (extensions && extensions.code) {
        throw new GraphqlError(message, extensions.code);
      }
      throw new GraphqlError(message, 'Default');
    }

    return response.data.data;
  };
};

/**
 * @author div
 * 현재 bff url을 향해 rest 방식으로 쏘고 있음
 * 내일 안으로 딘이 bff로 수정해주실 예정이고 그 후 삭제 예정
 * (그러나 당장 배너 클릭 로그가 쌓이지 않고 있어 일단은 이렇게 작업해서 배포)
 **/

export const gqlRestRequest = async (url: string, params?: any) => {
  const bffRestUrl = `${process.env.NEXT_PUBLIC_BFF_API_ENDPOINT}/api/`;
  if (!bffRestUrl) {
    return;
  }
  const headers = {
    'Content-Type': 'application/json',
  };
  const token = getAuthToken();

  if (token) {
    const accessToken = token.split('//')[0];
    set(headers, 'Authorization', `Bearer ${accessToken}`);
  }
  const axiosInstance: AxiosInstance = axios.create({
    baseURL: bffRestUrl,
    headers,
    timeout: 10000,
  });
  await axiosInstance.post(url, {
    ...params,
  });
};
