import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';

import useAppDownloadCujModal from '@/hooks/Cuj/useAppDownloadCujModal';
import useGetUserInfo from '@/hooks/auth/useGetUserInfo';
import useSetAuthOnScreenFocus from '@/hooks/auth/useSetAuthOnScreenFocus';
import { useGetCommunityUserQuery } from '@/hooks/community/mobile/community.gql.impl';
import useNeverSeeAgain from '@/hooks/useNeverSeeAgain';
import useToast from '@/hooks/useToast';
import { setAuthToken, tokenState } from '@/recoil/atoms/common/auth';
import { userState } from '@/recoil/atoms/common/user';
import { communityUserService } from '@/services/community';
import { User } from '@/types/Auth';
import { Client } from '@/types/app';
import { isServer } from '@/types/common';
import { instance } from '@/utils/fetcher';
import Firebase from '@/utils/firebase';
import { getUserAge } from '@/utils/format';
import Hackle from '@/utils/hackle';
import {
  isMobile,
  isMobileWebByUserAgent,
  isWebView,
  isWebViewByUserAgent,
} from '@/utils/mobile/RNPlugins';
import { resetUserWithDatadogRum, setUserWithDatadogRum } from '@/utils/report';

import AppDownloadCujFloating from '../cuj/AppDownloadCujFloating';

const GlobalNavigationBar = dynamic(
  () => import('@/components/layouts/GlobalNavigationBar'),
);
const Footer = dynamic(() => import('@/components/layouts/Footer'));
const GlobalHeader = dynamic(() => import('@/components/layouts/GlobalHeader'));
const AssignedNickNameOnBoarding = dynamic(
  () =>
    import(
      '@/components/common/CommunityOnBoarding/AssignedNickNameOnBoarding'
    ),
);
const RealtimeAnswerStatusBar = dynamic(
  () =>
    import('@/components/templates/communication/Qna/RealtimeAnswerStatusBar'),
);

interface Props {
  children: React.ReactNode;
  userAgent: string;
  client: Client;
  customGlobalSideBar?: React.ReactNode;
}

enum NONE_HEADER_PAGES {
  MEDICAL_CATEGORIES = 'hospitals/medical/categories',
  MEDICAL_TREATMENTS = 'hospitals/medical/treatments',
  BOOKINGS = 'bookings',
  WEB_DIAGNOSIS = 'diagnosis',
  PHARMACIES = 'pharmacies',
  HOSPITAL_EVENT = 'hospitals/event',
  CARDS = 'cards',
  FAMILY = 'family',
  ADDRESSES = 'addresses',
}

const noneHeaderPages = [
  NONE_HEADER_PAGES.MEDICAL_CATEGORIES,
  NONE_HEADER_PAGES.MEDICAL_TREATMENTS,
  NONE_HEADER_PAGES.BOOKINGS,
  NONE_HEADER_PAGES.WEB_DIAGNOSIS,
  NONE_HEADER_PAGES.PHARMACIES,
  NONE_HEADER_PAGES.HOSPITAL_EVENT,
  NONE_HEADER_PAGES.CARDS,
  NONE_HEADER_PAGES.FAMILY,
  NONE_HEADER_PAGES.ADDRESSES,
];

const DefaultLayout = ({ children, userAgent, customGlobalSideBar }: Props) => {
  useSetAuthOnScreenFocus();
  useNeverSeeAgain();

  const [showAssignedNicknameModal, setShowAssignedNicknameModal] =
    useState(false);
  const token = useRecoilValue(tokenState);
  const [user, setUser] = useRecoilState(userState);
  const { showToast } = useToast();
  const isMobileView = isMobile(userAgent);
  const isWebViewInApp = isWebViewByUserAgent(userAgent);
  const isMobileWebView = isMobileWebByUserAgent(userAgent);
  const router = useRouter();
  const pcContentRef = useRef<HTMLDivElement>(null);

  const isNoneHeaderPages = useMemo(() => {
    const path = router.pathname;
    return noneHeaderPages.some((noneHeaderPage) => {
      return path.includes(noneHeaderPage);
    });
  }, [router.pathname]);
  const hasCommunityPath = useMemo(() => {
    const asPath = router.asPath.toLowerCase();
    return asPath.includes('community') || asPath.includes('mypage');
  }, [router]);

  const isMainHome = useMemo(() => {
    return router.pathname === '/';
  }, [router]);

  const { setIsCujModalShow } = useAppDownloadCujModal();
  const { data: communityUser, refetch: communityUserRefetch } =
    useGetCommunityUserQuery(
      {},
      {
        enabled: !!token,
        select: (data) => communityUserService(data),
      },
    );
  useGetUserInfo({
    enabled: !!token && !isWebView(),
    useErrorBoundary: false,
    retry: 0,
    onSuccess: (data) => onSuccessGetUser(data.result, userAgent),
    onError: (error) => {
      if (error.response?.status === 401 && !isWebView()) {
        setAuthToken();
        instance.defaults.headers.common['Authorization'] = '';
        showToast({ message: '로그인 시간이 만료 되었습니다.' });
      }
    },
  });

  const resetUser = useCallback(
    (userAgent: string) => {
      setUser({});
      resetUserwithGAContext(userAgent);
      resetUserWithHackle();
      resetUserWithDatadogRum();
    },
    [setUser],
  );

  const onSuccessGetUser = (user: User, userAgent: string) => {
    setUser(user);
    setUserwithGAContext(user, userAgent);
    setUserWithHackle(user);
    setUserWithDatadogRum(user);
  };
  const resetUserwithGAContext = (userAgent: string) => {
    Firebase.setUserProperties({
      ...Firebase.getUserServiceEnvironmentProperty(userAgent),
      age: '',
      gender: '',
    });
  };

  const setUserwithGAContext = (user: User, userAgent: string) => {
    Firebase.setUserId(`${user.id}`);

    if (!user.birth && user.gender === 'none') return;
    Firebase.setUserProperties({
      ...Firebase.getUserServiceEnvironmentProperty(userAgent),
      age: String(getUserAge(user.birth)),
      gender: user.gender,
    });
  };

  const resetUserWithHackle = () => {
    Hackle.resetUser();
  };

  const setUserWithHackle = (user: User) => {
    Hackle.setUserId(`${user.id}`);
    if (user.deviceId) {
      Hackle.setDeviceId(`${user.deviceId}`);
    }

    if (!user.birth && user.gender === 'none') return;
    Hackle.setUserProperty('age', String(getUserAge(user.birth)));
    Hackle.setUserProperty('gender', user.gender);
  };

  const onCompleteOnBoardingCallback = async () => {
    await communityUserRefetch();
    setShowAssignedNicknameModal(false);
  };

  useEffect(() => {
    if (user) {
      Hackle.setUserId(`${user.userId}`);

      if (user.deviceId) {
        Hackle.setDeviceId(`${user.deviceId}`);
      }
    }
  }, [user]);
  useEffect(() => {
    //pc에서 이동 시 스크롤 위로
    if (pcContentRef.current) {
      pcContentRef.current.scroll({ top: 0 });
    }
  }, [router.asPath]);

  useEffect(() => {
    if (!isWebViewByUserAgent(userAgent) && !token) {
      resetUser(userAgent);
    }
  }, [token, resetUser, userAgent]);

  useEffect(() => {
    if (hasCommunityPath) {
      setShowAssignedNicknameModal(true);
      return;
    }
    setShowAssignedNicknameModal(false);
  }, [communityUser?.tutorialCompleted, hasCommunityPath]);

  useEffect(() => {
    if (isMobileWebView) {
      setIsCujModalShow(true);
    }
  }, [isMobileWebView, setIsCujModalShow]);

  if (isNoneHeaderPages) {
    return <>{children}</>;
  }

  if (isWebViewInApp) {
    return (
      <>
        {children}
        {!isServer && (
          <>
            {communityUser && (
              <AssignedNickNameOnBoarding
                isVisible={
                  showAssignedNicknameModal &&
                  !communityUser?.tutorialCompleted &&
                  !!token
                }
                profileImageUrl={communityUser.profileImageUrl}
                nickname={communityUser.nickname}
                onCompleteOnBoardingCallback={onCompleteOnBoardingCallback}
              />
            )}
          </>
        )}
      </>
    );
  }

  if (isMainHome) {
    return (
      <>
        {children}
        <Footer />
        {isMobileWebView && <AppDownloadCujFloating />}
      </>
    );
  }

  if (isMobileView) {
    return (
      <>
        <GlobalHeader isMobileWeb />
        {children}
        <Footer />
      </>
    );
  }

  return (
    <Wrapper>
      <Scroll ref={pcContentRef}>
        <GlobalNavigationBar />
        <Body>
          <Content>
            {children}
            <Footer />
          </Content>
          <RealtimeAnswerStatusBar />
        </Body>
        {customGlobalSideBar}
      </Scroll>
    </Wrapper>
  );
};

export default DefaultLayout;

const Wrapper = styled.div`
  display: flex;
  transition: all 0.4s ease-out;
  width: 100%;
`;

const Scroll = styled.div`
  display: flex;
  position: relative;
  min-width: 1196px;
  margin: 0 auto;
  height: 100%;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 768px;
  width: 768px;
  min-width: 600px;
  z-index: 1;
`;

const Content = styled.div`
  width: 100%;
  border-right: 1px solid ${({ theme }) => theme.color.gray40};
  border-left: 1px solid ${({ theme }) => theme.color.gray40};
`;
