/**
 * report 각 함수내 catch 구문에서 아무 처리 하지않는 것은
 * datadogLog 모듈 이슈에 대한 트래킹을 하지 않겠다는 것을 의미합니다
 * @author Elie
 */
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import { IS_LOCAL, IS_PROD_ZONE } from '@/constants/env';
import { User } from '@/types/Auth';
import {
  NATIVE_POST_MESSAGE_TYPE,
  isWebView,
  sendPostMessageToNative,
} from '@/utils/mobile/RNPlugins';

type MessageContext = Record<string, unknown>;
const getConditionalSampleRate = () => {
  const botPatternAgentRegex = new RegExp(
    '(googlebot/|bot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)',
    'i',
  );

  if (botPatternAgentRegex.test(navigator.userAgent)) {
    return 0;
  }

  const excludeUrlRegex = new RegExp(
    '(/content/magazine.*|/content/qna.*)',
    'i',
  );

  if (excludeUrlRegex.test(window.location.pathname)) {
    return 0;
  }

  if (window.location.search.indexOf('buzzvil') >= 0) {
    return 5;
  }

  return 100;
};

const getAllowedTracingUrls = () => {
  if (IS_LOCAL) {
    return undefined;
  }
  return IS_PROD_ZONE
    ? ['https://bff.doctornow.co.kr', 'https://api.doctornow.co.kr']
    : ['https://bff.dev.doctornow.co.kr', 'https://api.dev.doctornow.co.kr'];
};

const getVersion = () => {
  if (IS_LOCAL) {
    return 'local';
  }

  return IS_PROD_ZONE
    ? `dd-${process.env.IMAGE_TAG}`
    : `dd-dev-${process.env.IMAGE_TAG}`;
};

export const initDatadogMonitoring = () => {
  try {
    const conditionalSampleRate = getConditionalSampleRate();

    datadogLogs.init({
      clientToken: 'pub6ae158c35a813b91603f22d326fc6e7e',
      service: 'doctornow-user-web',
      // 고민포인트) getEnv()로 처리하는 것은 어떨까?
      env: IS_PROD_ZONE ? 'production' : 'development',
      version: getVersion(),
      forwardErrorsToLogs: IS_PROD_ZONE,
      sessionSampleRate: 100,
      silentMultipleInit: true,
      beforeSend: (event, context) => {
        /*
          https://github.com/DataDog/browser-sdk/issues/362#issuecomment-1758572518
          - status_code가 0인 경우는 네트워크 문제 및 연결 끊김 등으로 인한 에러로 판단하여 무시
        */
        if (event.error && event.http?.status_code === 0) {
          return false;
        }

        return !('isAborted' in context && context.isAborted);
      },
    });

    const allowedTracingUrls = getAllowedTracingUrls();

    datadogRum.init({
      applicationId: '4d6d9edd-bec7-40c1-8811-33d44009cd1b',
      clientToken: 'pub97ed1e2ca8c7c834e9276da481afd54d',
      site: 'datadoghq.com',
      service: 'doctornow-user-web',
      env: IS_PROD_ZONE ? 'production' : 'development',
      version: getVersion(),
      // 고민포인트) PR버전/ 빌드버전 등으로 자동 generate 되는 건 어떨까? (어떤 배포 영향인지 알수 있을듯)
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sessionSampleRate: conditionalSampleRate,
      // sessionReplaySampleRate: 100을 하더라도  startSessionReplayRecording를 호출해야만 녹화가 시작됨
      sessionReplaySampleRate: 0,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingUrls: allowedTracingUrls,
      beforeSend: (event, context) => {
        if (event.type === 'resource' && event.resource.type === 'fetch') {
          if (
            event.context &&
            'response' in context &&
            context.response?.headers
          ) {
            event.context.responseHeaders = Object.fromEntries(
              context.response.headers,
            );
          }

          if (
            event.context &&
            'requestInit' in context &&
            context.requestInit?.body
          ) {
            event.context.responseBody = context.requestInit.body;
          }
        }
        return true;
      },
    });
  } catch (error) {}
};

export const setUserWithDatadogRum = (user: User) => {
  datadogRum.setUser({
    id: user.userId,
    email: user.email,
    name: user.name,
    age: user.birth,
  });
};

export const resetUserWithDatadogRum = () => {
  datadogRum.clearUser();
};

const getMessageContext = (messageContext: MessageContext) => {
  if ('message' in messageContext) {
    const { message, ...rest } = messageContext;
    return JSON.parse(JSON.stringify({ ...rest, reportMessage: message }));
  }
  return JSON.parse(JSON.stringify(messageContext));
};
export const infoReport = (
  message: string,
  messageContext: MessageContext,
  error?: Error,
) => {
  try {
    if (IS_LOCAL) {
      console.log('report > reportInfo', message, messageContext, error);
    } else {
      datadogLogs.logger.info(
        message,
        getMessageContext(messageContext),
        error,
      );
    }
  } catch (error) {}
};

export const errorReport = (
  message: string,
  messageContext: MessageContext,
  error?: Error,
) => {
  try {
    if (IS_LOCAL) {
      console.log('report > reportError', message, messageContext, error);
    } else {
      datadogLogs.logger.error(
        message,
        getMessageContext(messageContext),
        error,
      );
    }
  } catch (error) {}
};

export const warningReport = (
  message: string,
  messageContext: MessageContext,
  error?: Error,
) => {
  try {
    if (IS_LOCAL) {
      console.log('report > reportWarning', message, messageContext, error);
    } else {
      datadogLogs.logger.warn(
        message,
        getMessageContext(messageContext),
        error,
      );
    }
  } catch (error) {}
};

export const debugReport = (
  message: string,
  messageContext: MessageContext,
  error?: Error,
) => {
  try {
    if (IS_LOCAL) {
      console.log('report > reportDebug', message, messageContext, error);
    } else {
      datadogLogs.logger.debug(
        message,
        getMessageContext(messageContext),
        error,
      );
    }
  } catch (error) {}
};

export const addActionForRum = (
  actionName: string,
  context?: Record<string, unknown>,
) => {
  try {
    if (IS_LOCAL) {
      console.log('tracking > addAction', actionName, context);
    } else {
      if (isWebView()) {
        sendPostMessageToNative(NATIVE_POST_MESSAGE_TYPE.ADD_ACTION_FOR_RUM, {
          actionName,
          params: context,
        });
      } else {
        datadogRum.addAction(actionName, context);
      }
    }
  } catch (error) {}
};
