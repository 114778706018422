import { atom } from 'recoil';
import { getRecoil, setRecoil } from 'recoil-nexus';

import { localStorageEffect, sessionStorageEffect } from '@/utils/atom';

export const CujTestDateState = atom<string[]>({
  key: 'testDate',
  default: [],
  effects: [localStorageEffect('testCujDate')],
});

export const getCujTestDateState = () => {
  return getRecoil(CujTestDateState);
};

export const setCujTestDateState = (date: string[]) => {
  setRecoil(CujTestDateState, date);
};

export const appDownloadCujModalState = atom<boolean>({
  key: 'appDownloadCujModalState',
  default: true,
  effects: [sessionStorageEffect('appDownLoadCujModal')],
});

export const setAppDownloadCujModalState = (state: boolean) => {
  setRecoil(appDownloadCujModalState, state);
};
