import { debounce } from 'lodash';
import { useSetRecoilState } from 'recoil';

import { toastState } from '@/recoil/atoms/common/toast';
import { ToastProps } from '@/types/Toast';

const useToast = () => {
  const setToasts = useSetRecoilState(toastState);

  const removeToast = (id: number) => {
    setToasts((oldToasts) => oldToasts.filter((toast) => toast.id !== id));
  };
  const _showToast = (props: ToastProps) => {
    const id = new Date().getTime();
    setToasts([{ props, id }]);
    return setTimeout(() => removeToast(id), (props.visibleTime || 2000) + 800);
  };

  const showToast = debounce(_showToast, 300);

  return {
    showToast,
  };
};

export default useToast;
