import { withOpacity } from '@/utils/opacity';

/**
 * string이 아닌 number로 사용되어야 할듯..
 */
const size = {
  mainContentsArea: '768px',
  tablet: '768px',
  appHeader: {
    height: '60px',
  },
  footerButton: {
    height: '60px',
  },
  stickyButton: {
    height: 108,
  },
  mobileWebHeader: {
    height: 60,
  },
  headerSearchBar: {
    height: 60,
  },
  realtimeAnswerStatusBar: {
    height: 57,
  },
  searchFilter: {
    height: 60,
  },
} as const;

const color = {
  primary100: '#FFF4E6',
  primary200: '#FFE8CC',
  primary300: '#FFD8A8',
  primary400: '#FFC078',
  primary500: '#FF8D00',
  primary600: '#FD7E14',
  primary700: '#F76707',
  primary800: '#E8590C',
  primary900: '#D9480F',

  blue100: '#E7F5FF',
  blue200: '#D0EBFF',
  blue300: '#A5D8FF',
  blue400: '#74C0FC',
  blue500: '#339AF0',
  blue600: '#228BE6',
  blue700: '#1C7ED6',
  blue800: '#1971C2',
  blue900: '#1864AB',

  green100: '#EBFBEE',
  green200: '#D3F9D8',
  green300: '#B2F2BB',
  green400: '#8CE99A',
  green500: '#51CF66',
  green600: '#40C057',
  green700: '#37B24D',
  green800: '#2F9E44',
  green900: '#2B8A3E',

  yellow100: '#FFF9DB',
  yellow200: '#FFF3BF',
  yellow300: '#FFEC99',
  yellow400: '#FFE066',
  yellow500: '#FCC419',
  yellow600: '#FAB005',
  yellow700: '#F59F00',
  yellow800: '#F08C00',
  yellow900: '#E67700',

  red100: '#FFF5F5',
  red200: '#FFE3E3',
  red300: '#FFC9C9',
  red400: '#FFA8A8',
  red500: '#FF6B6B',
  red600: '#FA5252',
  red700: '#F03E3E',
  red800: '#E03131',
  red900: '#C92A2A',

  gray10: '#FBFCFD',
  gray20: '#F7F9FA',
  gray30: '#F5F6F7',
  gray40: '#F1F3F6',
  gray50: '#EDEFF0',
  gray100: '#DFE1E2',
  gray200: '#C6CACE',
  gray300: '#A9AEB1',
  gray400: '#8D9297',
  gray500: '#71767A',
  gray600: '#565C65',
  gray700: '#3D4551',
  gray800: '#2D2E2F',
  gray900: '#1C1D1F',

  white: '#FFFFFF',
  black: '#000000',

  dimmed20: withOpacity('#000000', 0.2),
  dimmed40: withOpacity('#000000', 0.4),
  dimmed80: withOpacity('#000000', 0.8),
} as const;

const defaultTypography = `
  font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  font-style: normal;
  text-decoration: none;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
` as const;

const typography = {
  UI64B: `
      ${defaultTypography}
      font-weight: 700;
      font-size: 64px;
      line-height: 96px;
  `,
  UI56B: `
      ${defaultTypography}
      font-weight: 700;
      font-size: 56px;
      line-height: 68px;
  `,
  // UI Text (B/SB/M/R)
  UI48B: `
      ${defaultTypography}
      font-weight: 700;
      font-size: 48px;
      line-height: 60px;
    `,
  UI48SB: `
      ${defaultTypography}
      font-weight: 600;
      font-size: 48px;
      line-height: 60px;
    `,
  UI40B: `
      ${defaultTypography}
      font-weight: 700;
      font-size: 40px;
      line-height: 52px;
    `,
  UI40SB: `
      ${defaultTypography}
      font-weight: 600;
      font-size: 40px;
      line-height: 52px;
    `,
  UI36B: `
      ${defaultTypography}
      font-weight: 700;
      font-size: 36px;
      line-height: 48px;
    `,
  UI36SB: `
      ${defaultTypography}
      font-weight: 600;
      font-size: 36px;
      line-height: 48px;
    `,
  UI32B: `
      ${defaultTypography}
      font-weight: 700;
      font-size: 32px;
      line-height: 42px;
    `,
  UI32SB: `
      ${defaultTypography}
      font-weight: 600;
      font-size: 32px;
      line-height: 42px;
    `,
  UI28B: `
      ${defaultTypography}
      font-weight: 700;
      font-size: 28px;
      line-height: 38px;
    `,
  UI28SB: `
      ${defaultTypography}
      font-weight: 600;
      font-size: 28px;
      line-height: 38px;
    `,
  UI26B: `
      ${defaultTypography}
      font-weight: 700;
      font-size: 26px;
      line-height: 36px;
    `,
  UI26SB: `
      ${defaultTypography}
      font-weight: 600;
      font-size: 26px;
      line-height: 36px;
    `,
  UI24B: `
      ${defaultTypography}
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
    `,
  UI24SB: `
      ${defaultTypography}
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
    `,
  UI24M: `
      ${defaultTypography}
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
    `,
  UI22B: `
      ${defaultTypography}
      font-weight: 700;
      font-size: 22px;
      line-height: 30px;
    `,
  UI22SB: `
      ${defaultTypography}
      font-weight: 600;
      font-size: 22px;
      line-height: 30px;
    `,
  UI20B: `
      ${defaultTypography}
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
    `,
  UI20SB: `
      ${defaultTypography}
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
    `,
  UI20M: `
      ${defaultTypography}
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
    `,
  UI20R: `
      ${defaultTypography}
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
    `,
  UI19B: `
      ${defaultTypography}
      font-weight: 700;
      font-size: 19px;
      line-height: 26px;
    `,
  UI19SB: `
      ${defaultTypography}
      font-weight: 600;
      font-size: 19px;
      line-height: 26px;
    `,
  UI19M: `
      ${defaultTypography}
      font-weight: 500;
      font-size: 19px;
      line-height: 26px;
    `,
  UI19R: `
      ${defaultTypography}
      font-weight: 400;
      font-size: 19px;
      line-height: 26px;
    `,
  UI18B: `
      ${defaultTypography}
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
    `,
  UI18SB: `
      ${defaultTypography}
      font-weight: 600;
      font-size: 18px;
      line-height: 26px;
    `,
  UI18M: `
      ${defaultTypography}
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
    `,
  UI18R: `
      ${defaultTypography}
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
    `,
  UI17B: `
      ${defaultTypography}
      font-weight: 700;
      font-size: 17px;
      line-height: 24px;
    `,
  UI17SB: `
      ${defaultTypography}
      font-weight: 600;
      font-size: 17px;
      line-height: 24px;
    `,
  UI17M: `
      ${defaultTypography}
      font-weight: 500;
      font-size: 17px;
      line-height: 24px;
    `,
  UI17R: `
      ${defaultTypography}
      font-weight: 400;
      font-size: 17px;
      line-height: 24px;
    `,
  UI16B: `
      ${defaultTypography}
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    `,
  UI16SB: `
      ${defaultTypography}
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    `,
  UI16M: `
      ${defaultTypography}
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    `,
  UI16R: `
      ${defaultTypography}
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    `,
  UI15B: `
      ${defaultTypography}
      font-weight: 700;
      font-size: 15px;
      line-height: 22px;
    `,
  UI15SB: `
      ${defaultTypography}
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;
    `,
  UI15M: `
      ${defaultTypography}
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
    `,
  UI15R: `
      ${defaultTypography}
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
    `,
  UI14B: `
      ${defaultTypography}
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
    `,
  UI14SB: `
      ${defaultTypography}
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    `,
  UI14M: `
      ${defaultTypography}
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    `,
  UI14R: `
      ${defaultTypography}
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    `,
  UI13B: `
      ${defaultTypography}
      font-weight: 700;
      font-size: 13px;
      line-height: 18px;
    `,
  UI13SB: `
      ${defaultTypography}
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
    `,
  UI13M: `
      ${defaultTypography}
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
    `,
  UI13R: `
      ${defaultTypography}
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
    `,
  UI12B: `
      ${defaultTypography}
      font-weight: 700;
      font-size: 12px;
      line-height: 18px;
    `,
  UI12SB: `
      ${defaultTypography}
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
    `,
  UI12M: `
      ${defaultTypography}
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
    `,
  UI12R: `
      ${defaultTypography}
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
    `,
  UI11SB: `
      ${defaultTypography}
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
    `,
  // UI Sub (M/R)
  UI11M: `
      ${defaultTypography}
      font-weight: 500;
      font-size: 11px;
      line-height: 14px;
    `,
  UI11R: `
      ${defaultTypography}
      font-weight: 400;
      font-size: 11px;
      line-height: 14px;
    `,
  UI10SB: `
    ${defaultTypography}
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
  `,
  UI10M: `
    ${defaultTypography}
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
  `,
  UI10R: `
    ${defaultTypography}
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
  `,
  ARTICLE18M: `
      ${defaultTypography}
      font-weight: 500;
      font-size: 18px;
      line-height: 32px;
    `,
  ARTICLE18R: `
      ${defaultTypography}
      font-weight: 400;
      font-size: 18px;
      line-height: 32px;
    `,
  ARTICLE16M: `
      ${defaultTypography}
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;
    `,
  ARTICLE16R: `
      ${defaultTypography}
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
    `,
} as const;

const fontWeight = {
  BOLD: 700,
  SEMI_BOLD: 600,
  MEDIUM: 500,
  REGULAR: 400,
} as const;

const shadow = {
  BOX_SHADOW_LARGE:
    '0px 8px 16px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(0, 0, 0, 0.04)',
  BUTTON_SHADOW_SMALL: '0px 2px 4px rgba(0, 0, 0, 0.08)',
  DROP_DOWN_SHADOW_SMALL: '0px 4px 8px rgba(0, 0, 0, 0.08)',
} as const;

const breakPoint = {
  SMALL_MOBILE: '400px',
};

const theme = {
  size,
  color,
  typography,
  fontWeight,
  shadow,
  breakPoint,
};

export default theme;
