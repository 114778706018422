import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import useScreenFocus from '@/hooks/useScreenFocus';
import { tokenState } from '@/recoil/atoms/common/auth';
import { userState } from '@/recoil/atoms/common/user';
import { isWebView } from '@/utils/mobile/RNPlugins';

const useSetAuthOnScreenFocus = () => {
  const focused = useScreenFocus();
  const setToken = useSetRecoilState(tokenState);
  const setUser = useSetRecoilState(userState);

  useEffect(() => {
    if (isWebView()) {
      if (!window?.appToken || window?.appToken === 'undefined') {
        setToken('');
      } else {
        setToken(window.appToken);
      }
      if (!window?.appUser || window?.appUser === 'undefined') {
        setUser(undefined);
      } else {
        setUser(JSON.parse(window.appUser));
      }
    }
  }, [focused, setToken, setUser]);
};

export default useSetAuthOnScreenFocus;
