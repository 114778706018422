import camelCase from 'lodash/camelCase';
import set from 'lodash/set';
import { NextRouter } from 'next/router';

import { IS_LOCAL } from '@/constants/env';
import { CommonLoggerEventName } from '@/constants/logs/common';
import Pixel from '@/utils/pixel';

import Firebase from '../firebase';
import {
  NATIVE_POST_MESSAGE_TYPE,
  isWebView,
  sendPostMessageToNative,
} from '../mobile/RNPlugins';

export interface LoggerProps {
  eventName: string;
  pageName: string;
  payload?: {
    [key: string]: string | number;
  };
}

export interface MobileLoggerParams {
  logger: {
    eventName: string;
    pageName: string;
    payload?: {
      [key: string]: string | number;
    };
  };
}

export enum DomainTypeForLogger {
  WEB_DIAGNOSIS = 'web_diagnosis',
  COMMUNITY = 'community',
  QNA = 'qna',
  MAGAZINE = 'magazine',
  FACE_TO_FACE = 'face_to_face',
  CORE = 'core',
  FAQ = 'faq',
}

export const logger = ({ eventName, pageName, payload }: LoggerProps) => {
  if (IS_LOCAL) {
    console.log('logger >>', { eventName, pageName, payload } ?? '');
  }

  const attributes = {
    page_name: pageName,
  };

  if (payload) {
    Object.entries(payload).forEach(([key, value]) => {
      if (typeof value === 'number') {
        set(attributes, key, value.toString());
      } else {
        set(attributes, key, value);
      }
    });
  }
  Firebase.logEvent(eventName, attributes);
};

const PIXEL_EVENT_NAME_LIST = [
  'click_survey_start',
  'click_survey_submit',
  'click_counseling_request',
  'complete_survey_submit',
  'signup',
];
const evaluatePixelLog = (props: MobileLoggerParams) => {
  if (isWebView()) return;

  const { eventName, pageName } = props.logger;
  if (PIXEL_EVENT_NAME_LIST.includes(eventName)) {
    Pixel.trackCustom(eventName, {
      page_name: pageName,
    });
  }
};

export const mobileLogger = (propsParams: MobileLoggerParams) => {
  if (IS_LOCAL) {
    return console.log('logger >>', { ...propsParams } ?? '');
  }

  /* GA로그에서 이벤트명은 40자까지 허용 */
  const params = {
    logger: {
      ...propsParams.logger,
      eventName: propsParams.logger.eventName.slice(0, 40),
    },
  };

  if (
    params.logger.eventName === CommonLoggerEventName.PageView &&
    !params.logger.pageName.trim()
  ) {
    return;
  }

  evaluatePixelLog(params);

  if (isWebView()) {
    return sendPostMessageToNative(NATIVE_POST_MESSAGE_TYPE.LOGGER, {
      ...params,
    });
  }

  logger({ ...params.logger });
};

/**
 *
 * @param url NextRouter
 *
 */
export const getPageName = (url: NextRouter) => {
  return getPageNameForUrlPathname(url.pathname);
};

export const getMobileWebPageName = (url: NextRouter) => {
  return getPageName(url);
};

const toCamelCase = (str: string) => {
  return camelCase(str);
};
const camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const getPageNameForLogging = (url: string) => {
  return url
    .split('/')
    .slice(1)
    .filter((path) => !path.includes('['))
    .map(camelToSnakeCase)
    .join('_');
};

const removeSquareBrackets = (str: string) => {
  return str.replace(/\[|\]/g, '');
};

const replaceIdPath = (str: string) => {
  return str.replace(/\[([^\]]*?)([iI][dD])([^\]]*?)\]/g, (match) => {
    if (/[iI][dD]/.test(match)) {
      return `[id]`;
    } else {
      return match;
    }
  });
};

const limitMaxLength = (str: string, length: number) => {
  return str.length > length ? str.slice(0, length) : str;
};

const removeDashbar = (str: string) => {
  return str.replace(/-/g, '');
};

export const getPageNameForUrlPathname = (url: string) => {
  const formattedPageName = url
    .split('/')
    .slice(1)
    .map(replaceIdPath)
    .map(removeSquareBrackets)
    .map(removeDashbar)
    .map((path) => limitMaxLength(path, 12))
    .join('_')
    .slice(0, 40);

  return formattedPageName === '' ? 'home' : formattedPageName;
};

export const getEventNameForLogging = (onEventName: string) => {
  return camelToSnakeCase(toCamelCase(onEventName.replace(/^on/g, '')));
};
export const coreLogger = (props: LoggerProps) => {
  mobileLogger({
    logger: {
      ...props,
      payload: {
        domain: DomainTypeForLogger.CORE,
        ...props.payload,
      },
    },
  });
};

export const communityLogger = (props: LoggerProps) => {
  mobileLogger({
    logger: {
      ...props,
      payload: {
        domain: DomainTypeForLogger.COMMUNITY,
        ...props.payload,
      },
    },
  });
};

export const qnaLogger = (props: LoggerProps) => {
  mobileLogger({
    logger: {
      ...props,
      payload: {
        domain: DomainTypeForLogger.QNA,
        platform: isWebView() ? 'App' : 'Web(PC/Mobile)',
        ...props.payload,
      },
    },
  });
};

export const magazineLogger = (props: LoggerProps) => {
  mobileLogger({
    logger: {
      ...props,
      payload: {
        domain: DomainTypeForLogger.MAGAZINE,
        platform: isWebView() ? 'App' : 'Web(PC/Mobile)',
        ...props.payload,
      },
    },
  });
};

export const faqLogger = (props: LoggerProps) => {
  mobileLogger({
    logger: {
      ...props,
      payload: {
        domain: DomainTypeForLogger.FAQ,
        platform: isWebView() ? 'App' : 'Web(PC/Mobile)',
        ...props.payload,
      },
    },
  });
};

export const faceTofaceLogger = (props: LoggerProps) => {
  mobileLogger({
    logger: {
      ...props,
      payload: {
        domain: DomainTypeForLogger.FACE_TO_FACE,
        platform: isWebView() ? 'App' : 'Web(PC/Mobile)',
        ...props.payload,
      },
    },
  });
};
