export const BFF_ERROR_CODE_TYPES = {
  Default: 'Default',
  SignInFailed: 'SignInFailed',
  NoAuthorization: 'NoAuthorization',
  canceled: 'canceled',
  AlreadyReceivedFaceToFaceDiagnosisBooking:
    'AlreadyReceivedFaceToFaceDiagnosisBooking',
  MaxCountExceeded: 'MaxCountExceeded',
  UnableBooking: 'UnableBooking',
} as const;
export type BFF_ERROR_CODE_TYPES =
  typeof BFF_ERROR_CODE_TYPES[keyof typeof BFF_ERROR_CODE_TYPES];

export const DNA_ERROR_CODE_TYPES = {
  DuplicateOrderException: 'DuplicateOrderException',
  TooManySendException: 'TooManySendException',
} as const;
export type DNA_ERROR_CODE_TYPES =
  typeof DNA_ERROR_CODE_TYPES[keyof typeof DNA_ERROR_CODE_TYPES];

export const ERROR_MESSAGE = {
  DefaultError: '일시적인 오류가 발생했습니다.',
  NoAuthTokenException: '로그인 후 이용 가능합니다.',
  NoAuthorization: '로그인 후 이용 가능합니다.',
  SimpleSurveyAnswerAddFailed: '최대 500자까지 등록가능합니다.',
  AlreadyReceivedFaceToFaceDiagnosisBooking:
    '이미 접수된 대면진료 예약이 있어요',
  MaxCountExceeded:
    '선택하신 시간은 예약이 마감 되었어요. 다른 시간을 선택해 주세요.',
  UnableBooking: '현재 예약 기능을 사용할 수 없어요.',
} as const;
