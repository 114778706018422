import React from 'react';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { zIndex } from '@/constants/zIndex';
import { toastState } from '@/recoil/atoms/common/toast';
import { isMobile } from '@/utils/mobile/RNPlugins';

import ToastItem from './ToastItem';
import ToastMobileItem from './ToastMobileItem';

interface Props {
  userAgent: string;
}

const Toast = ({ userAgent }: Props) => {
  const toasts = useRecoilValue(toastState);
  const isMobileView = isMobile(userAgent);

  if (!isMobileView) {
    return (
      <Wrapper>
        {toasts.map((toast, index) => {
          return (
            <ToastItem key={`toast-${toast.id}`} toast={toast} index={index} />
          );
        })}
      </Wrapper>
    );
  }
  return (
    <MobileWrapper>
      {toasts.map((toast, index) => {
        return (
          <ToastMobileItem
            key={`toast-${toast.id}`}
            toast={toast}
            index={index}
          />
        );
      })}
    </MobileWrapper>
  );
};

export default Toast;

const Wrapper = styled.div`
  z-index: ${zIndex.Toast};
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 32px;
  width: fit-content;
`;

const MobileWrapper = styled.ul`
  z-index: ${zIndex.Toast};
  position: fixed;
  bottom: 32px;
  width: calc(100% - 32px);
  margin: 0 16px;
`;
