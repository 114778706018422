export class GraphqlError extends Error {
  /**
   * @type {string}
   * 서버에서 내려온 코드를 확인할 수 있습니다.
   */
  code: string;
  /**
   * @type {string}
   * 서버에서 내려온 message를 확인할 수 있습니다.
   */
  errorMessage: string;
  /**
   * @deprecated
   * @type {string}
   * 이전 레거시 코드들을 대응하기 위해 message에는 code값이 들어있습니다.
   */
  message: string;

  constructor(message: string, code: string) {
    super(message);
    this.code = code;
    this.message = code;
    this.errorMessage = message;
  }
}
export enum ServerError {
  NotFound = 'NotFound',
  BadRequest = 'BadRequest',
}
