import styled from 'styled-components';

export const EllipsisTextStyle = `
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: keep-all;
  `;

export const LineClampTextStyled = styled.div<{
  lineNumber: number;
  isSelectable?: boolean;
}>`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  ${({ lineNumber }) => `-webkit-line-clamp: ${lineNumber}`};
  overflow: hidden;

  ${({ isSelectable }) =>
    isSelectable &&
    `
    -webkit-user-select: text;
    user-select: text;
  `}
`;

export const SelectableText = styled.p`
  -webkit-user-select: text;
  user-select: text;
`;
