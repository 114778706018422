import { FunctionComponent } from 'react';

import Spinner from '@/components/atom/Spinner';
import { LoaderContainer } from '@/components/molecules/Loader/styles';

const Loader: FunctionComponent = () => {
  return (
    <LoaderContainer>
      <Spinner />
    </LoaderContainer>
  );
};
export default Loader;
