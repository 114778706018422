import {
  GetBoardPostQuery,
  GetMyBoardCommentQuery,
  GetMyBoardPostCursorPageQuery,
  GetNewestBoardPostCursorPageQuery,
  GetRecommendedPopularBoardPostsQuery,
  GetSearchBoardPostCursorPageQuery,
} from './bff/gql-schema';

export type LatestHotBoardPosts =
  GetRecommendedPopularBoardPostsQuery['recommendedPopularBoardPosts'][0];

export type NewestBoardPostDto =
  GetNewestBoardPostCursorPageQuery['newestBoardPostCursorPage']['elements'][0];
export type SearchBoardPostDto =
  GetSearchBoardPostCursorPageQuery['searchBoardPostCursorPage']['elements'][0];
export type MyBoardPostDto =
  GetMyBoardPostCursorPageQuery['myBoardPostCursorPage']['elements'][0];

export const BOARD_POST_TAG_TYPE = {
  DEFAULT: 'DEFAULT',
  RECOMMENDED: 'RECOMMENDED',
  POPULAR: 'POPULAR',
};

export type BOARD_POST_TAG_TYPE =
  typeof BOARD_POST_TAG_TYPE[keyof typeof BOARD_POST_TAG_TYPE];

export interface NewestBoardPostTag {
  type: BOARD_POST_TAG_TYPE;
  label: string;
}

export interface BoardPostVo {
  boardPostPid: string;
  title: string;
  content: string;
  createdAt: number;
  isPopular: boolean;
  isRecommended: boolean;
  likeCount: number;
  boardCommentCount: number;
  isLikedByMe: boolean;
  isMine: boolean;
  blinded: boolean;
  boardPostAttachments: Array<{
    boardPostAttachmentId: string;
    boardPostPid: string;
    blindType: AttachmentBlindType;
  }>;
  communityUser: {
    nickname: string;
    userId: string;
    profileImageUrl: string;
  };
  boardCategory: { name: string };
  formattedCreatedAt: string;
  tags: Array<NewestBoardPostTag>;
}

export interface NewestBoardPost extends NewestBoardPostDto {
  formattedCreatedAt: string;
  tags: Array<NewestBoardPostTag>;
}
export interface SearchBoardPost extends SearchBoardPostDto {
  formattedCreatedAt: string;
  tags: Array<NewestBoardPostTag>;
}

export interface MyBoardPost extends MyBoardPostDto {
  formattedCreatedAt: string;
  tags: Array<NewestBoardPostTag>;
}

type BoardCommentDto = GetBoardPostQuery['boardPost']['boardComments'][0];

export interface BoardComment extends BoardCommentDto {
  formattedCreatedAt: string;
}
export interface BoardPostDetail
  extends Omit<GetBoardPostQuery['boardPost'], 'boardComments'> {
  formattedCreatedAt: string;
  boardComments: Array<BoardComment>;
}

type MyBoardCommentDto =
  GetMyBoardCommentQuery['myBoardCommentCursorPage']['elements'][0];

export interface MyBoardComment extends MyBoardCommentDto {
  formattedCreatedAt: string;
}
export interface CommunityUserProfile {
  nickname: string;
  profileImageUrl: string;
}

export interface CommunityUser extends CommunityUserProfile {
  tutorialCompleted: boolean;
  userId: string;
  isAdmin: boolean;
  bannedUntil?: number;
  formattedBannedDate?: string;
  isBanned?: boolean;
}

export interface CommunityImage {
  // 요건 실제 업데이트 path
  path: string;
  // 화면에 보여줘야할 이미지 url
  previewUrl?: string;
}

export interface CommunityBottomSheetCategoriesDto {
  boardCategoryId: string;
  name: string;
}

export enum AttachmentBlindType {
  Blinded = 'BLINDED',
  Dimmed = 'DIMMED',
  None = 'NONE',
}

export interface BoardPostDetailResponse {
  boardPostPid: string;
  boardCategoryId: string;
  title: string;
  content: string;
  likeCount: number;
  boardCommentCount: number;
  createdAt: number;
  isLikedByMe: boolean;
  isMine: boolean;
  blinded: boolean;
  boardPostAttachments: Array<{
    boardPostAttachmentId: string;
    blindType: AttachmentBlindType;
  }>;
  communityUser: {
    nickname: string;
    userId: string;
    profileImageUrl: string;
  };
  boardCategory: { name: string };
  boardComments: Array<{
    boardCommentPid: string;
    parentBoardCommentPid: string;
    boardPostPid: string;
    content: string;
    createdAt: number;
    deleted: boolean;
    isLikedByMe: boolean;
    isMine: boolean;
    likeCount: number;
    blinded: boolean;
    communityUser: {
      nickname: string;
      userId: string;
      profileImageUrl: string;
    };
  }>;
}

export interface boardCategoryVo {
  readonly boardCategoryId: string;
  readonly imageUrl: string;
  readonly name: string;
}

export type LatestHotBoardPost = {
  readonly boardPostPid: string;
  readonly isPopular: boolean;
  readonly isRecommended: boolean;
  readonly title: string;
};

export interface MagazineRelatedPost {
  readonly boardPostPid: string;
  readonly title: string;
  readonly content: string;
  readonly boardPostAttachmentId: string | null;
}
