import React from 'react';

interface Props {
  width?: number;
  height?: number;
}

const WarningCircle = ({ width = 56, height = 56 }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
        fill="#FCC419"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31 15.5C31 13.8432 29.6568 12.5 28 12.5C26.3432 12.5 25 13.8432 25 15.5V28.5C25 30.1568 26.3432 31.5 28 31.5C29.6568 31.5 31 30.1568 31 28.5V15.5ZM28 43.5C30.2091 43.5 32 41.7092 32 39.5C32 37.2909 30.2091 35.5 28 35.5C25.7909 35.5 24 37.2909 24 39.5C24 41.7092 25.7909 43.5 28 43.5Z"
        fill="white"
      />
    </svg>
  );
};

export default WarningCircle;
