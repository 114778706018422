import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import useWindowFocus from '@/hooks/useWindowFocus';
import { neverSeeAgainState } from '@/recoil/atoms/common/neverSeeAgain';
import { isWebView } from '@/utils/mobile/RNPlugins';

const useNeverSeeAgain = () => {
  const focused = useWindowFocus();
  const setNeverSeeAgain = useSetRecoilState(neverSeeAgainState);

  useEffect(() => {
    if (!isWebView()) {
      return;
    }
    if (
      !window?.neverSeeAgainState ||
      window?.neverSeeAgainState === 'undefined'
    ) {
      setNeverSeeAgain({});
    } else {
      setNeverSeeAgain(JSON.parse(window.neverSeeAgainState));
    }
  }, [focused, setNeverSeeAgain]);
};

export default useNeverSeeAgain;
