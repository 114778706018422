import Script from 'next/script';

import { isWebViewByUserAgent } from '@/utils/mobile/RNPlugins';

interface Props {
  userAgent: string;
}
const NaverAnalyticsScript = ({ userAgent }: Props) => {
  if (isWebViewByUserAgent(userAgent)) return null;

  return (
    <>
      <Script src="//wcs.naver.net/wcslog.js" async />
      <Script
        id="next-naver-analytics"
        dangerouslySetInnerHTML={{
          __html: `
          if(!wcs_add) var wcs_add = {};
          wcs_add["wa"] = "1cb7f323096f1a0";
          if(window.wcs) {
              wcs_do();
            }
        `,
        }}
      />
    </>
  );
};

export default NaverAnalyticsScript;
