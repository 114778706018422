import { useEffect, useState } from 'react';

const hasFocus = () => typeof document !== 'undefined' && document.hasFocus();

interface EventListenerObject extends Event {
  data: string;
}

const useScreenFocus = () => {
  const [focused, setFocused] = useState(hasFocus); // Focus for first render

  useEffect(() => {
    const androidFocusHandler = (event: EventListenerObject) => {
      if (
        event.data &&
        Object.keys(JSON.parse(event.data)).includes('isFocused')
      ) {
        setFocused(JSON.parse(event.data).isFocused);
      }
    };

    document.addEventListener('message', androidFocusHandler as EventListener);
    return () => {
      document.removeEventListener(
        'message',
        androidFocusHandler as EventListener,
      );
    };
  }, []);

  return focused;
};

export default useScreenFocus;
