export const INFLUENZA = 'influenza';
export const INFLUENZA_DEFAULT_PLACEHOLDER = '[ 독감예방접종 ]';

export enum FaceToFaceLog {
  click_category = 'click_category',
  click_category_treatment = 'click_category_treatment',
  click_popular_keyword = 'click_popular_keyword',
  click_search_keyword = 'click_search_keyword',
  click_filter_nonF2f = 'click_filter_nonF2f',
  click_filter_sunday_operation = 'click_filter_sunday_operation',
  click_filter_detail = 'click_filter_detail',
  click_filter_sorting = 'click_filter_sorting',
  click_filter_operating = 'click_filter_operating',
  click_filter_easy_booking = 'click_filter_easy_booking',
  click_filter_professional = 'click_filter_professional',
  click_filter_night_operation = 'click_filter_night_operation',
  click_filter_detail_apply = 'click_filter_detail_apply',
}
